import React from 'react';

const Switcher = ({ name, value, onChange }) => {
  const onChangeHandler = (event) => {
    onChange(event.target.checked);
  };

  return (
    <label className={'mnt-Switcher'}>
      <div className={'mnt-Switch'}>
        <input type="checkbox" checked={value} onChange={onChangeHandler} />
        <span className={'mnt-slider'} />
        <span className={'mnt-label'}>{name}</span>
      </div>
    </label>
  );
};

export default Switcher;
