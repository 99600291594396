import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import MenuSectionTitle from '../MenuSectionTitle';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';

const MenuSkeleton = ({ isDarkMode }) => {
  const color = isDarkMode ? '#222' : '#eee';
  const highlightColor = isDarkMode ? '#444' : '#f5f5f5';
  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      <nav className={'mnt-sideMenu'}>
        <div>
          <Skeleton style={{ fontSize: 20, lineHeight: 2, marginTop: '5px' }} />
        </div>
        <div>
          <Skeleton style={{ fontSize: 20, lineHeight: 2, marginTop: '5px' }} />
        </div>
        <div>
          <Skeleton style={{ fontSize: 20, lineHeight: 2, marginTop: '5px' }} />
        </div>
      </nav>
      <div className={'mnt-sideNav'}>
        <MenuSectionTitle>
          <Skeleton width={'50%'} />
        </MenuSectionTitle>
        <nav className={'mnt-tags'}>
          {new Array(3).fill(0).map((_, i) => (
            <Skeleton key={i} width={'120px'} height={'30px'} />
          ))}
        </nav>
      </div>
      <div className={'mnt-sideNav'}>
        <MenuSectionTitle>
          <Skeleton width={'50%'} />
        </MenuSectionTitle>
        <nav className={'mnt-sideMenu'}>
          {new Array(3).fill(0).map((_, i) => (
            <div key={i}>
              <Skeleton style={{ fontSize: 20, lineHeight: 2, marginTop: '5px' }} />
            </div>
          ))}
        </nav>
      </div>
    </SkeletonTheme>
  );
};

export default withUserSettingsContext(MenuSkeleton);
