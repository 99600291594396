import React from 'react';
import SpriteMap from './SpriteMap';
import { Link } from 'react-router-dom';

const EndOfFeed = () => {
  return (
    <div className={'mnt-endOfFeed'}>
      <div className={'mnt-logoMnt'}>
        <span className={`mnt-logo mnt-logo-${APP_LANG}`}>
          <SpriteMap sprite={`logo_mnt-${APP_LANG}`} />
        </span>
      </div>
    </div>
  );
};

export default EndOfFeed;
