import React from 'react';
import PropTypes from 'prop-types';

const LivesTime = ({ dateString, className }) => {
  const dateObject = new Date(dateString);

  let dateTimeString = '';

  const leadingZeros = (number) => {
    return ('00' + number).substr(-2);
  };

  dateTimeString +=
    ' ' +
    leadingZeros(new Intl.DateTimeFormat([], { hour: '2-digit', timeZone: 'Europe/Bratislava', hour12: false }).format(dateObject)) +
    ':' +
    leadingZeros(new Intl.DateTimeFormat('sk-SK', { minute: '2-digit', timeZone: 'Europe/Bratislava' }).format(dateObject));

  return <span className={`${className ? className : ''}`}>{dateTimeString}</span>;
};

LivesTime.propTypes = {
  dateString: PropTypes.string.isRequired,
};

export default LivesTime;
