import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

const CopyToClipboard = ({ url, onLinkCopied, children }) => {
  const onLinkCopyUrlToClipboard = (event) => {
    copy(url);
    onLinkCopied();
    event.preventDefault();
    return false;
  };

  return (
    <a href={'#'} onClick={onLinkCopyUrlToClipboard}>
      {children}
    </a>
  );
};

CopyToClipboard.propTypes = {
  url: PropTypes.string.isRequired,
  /// onLinkCopied callback can be used to provide response to the user,
  /// after the copying has been done
  onLinkCopied: PropTypes.func.isRequired,
};

export default CopyToClipboard;
