import React, { useEffect, useState } from 'react';
import { executePostsCall, executeSetupCall } from '../../api_definitions';
import { useLocation } from 'react-router-dom';

function useRedirectOldRoutes(redirectOldURLByHash) {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (redirectOldURLByHash) {
      redirectOldURLByHash
        .filter((it) => it.fastRecognize(location.hash))
        .forEach((it) => {
          const slug = it.pullSlug(location.hash);
          if (it.type === 'tag') {
            setIsLoading(true);
            resolveTag(slug, it.redirectToURL);
          } else if (it.type === 'category') {
            resolveCategory(slug, it.redirectToURL);
          }
        });
    }
  }, [location]);

  const resolveTag = async (tagSlug, redirectToURLFn) => {
    try {
      const response = await executePostsCall({ tag: tagSlug });
      if (response.meta && response.meta.type === 'tag') {
        setRedirectTo(redirectToURLFn(response.meta.id, tagSlug));
      } else {
        throw new Error('tag not found');
      }
    } catch (ex) {
      setNotFound(true);
    }
  };

  const resolveCategory = async (slug, redirectToURLFn) => {
    try {
      const response = await executeSetupCall();
      if (response.categories) {
        let found = false;
        response.categories.forEach((category) => {
          if (category.slug === slug) {
            found = true;
            setRedirectTo(redirectToURLFn(category.id, slug));
          }
        });
        if (!found) throw new Error('Category not found');
      }
    } catch (ex) {
      setNotFound(true);
    }
  };

  return [isLoading, redirectTo, notFound];
}

export default useRedirectOldRoutes;
