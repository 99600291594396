import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fulfilledFetchReader, fulfilledfetchReaderTrending } from '../../Redux/readerSlice';
import { fulfilledFetchSetup } from '../../Redux/setupSlice';

const WorkboxCacheHandler = ({ children }) => {
  const dispatch = useDispatch();

  const onNewMessage = async (event) => {
    if (event.meta === 'workbox-broadcast-update') {
      if (event.payload.routeName && event.payload.routeName) {
        /// Read new json from cache
        const cache = await caches.open(event.payload.cacheName);
        const updatedResponse = await cache.match(event.payload.updatedURL);
        const updatedApi = await updatedResponse.json();

        /// run reducer based on routeName and meta.type(for reader/posts)
        if (event.payload.routeName === 'setup') {
          dispatch(fulfilledFetchSetup({ payload: updatedApi }));
        } else if (event.payload.routeName === 'readerPosts') {
          if (updatedApi.meta && updatedApi.meta.type && updatedApi.meta.type === 'trending') {
            dispatch(fulfilledfetchReaderTrending({ payload: updatedApi }));
          } else {
            dispatch(fulfilledFetchReader({ payload: updatedApi }));
          }
        }
      }
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', onNewMessage);
      return () => {
        navigator.serviceWorker.removeEventListener('message', onNewMessage);
      };
    }
  }, []);

  return <>{children}</>;
};

export default WorkboxCacheHandler;
