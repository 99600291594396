import React from 'react';
import SpriteMap from './SpriteMap';
import { useSelector } from 'react-redux';
import { canEditSelector } from '../Redux/setupSlice';

const AdminEditLink = ({ url, id }) => {
  /// return empty fragment if current user does not have permission to edit posts
  const canEdit = useSelector(canEditSelector);
  if (!canEdit) return <></>;

  const customizedUrl = url.replace('{id}', id);
  return (
    <a href={customizedUrl} className={'mnt-External mnt-adminEdit'} target="_blank" rel="noreferrer" title={'Edit'}>
      <SpriteMap sprite={'edit'} />
    </a>
  );
};

export default AdminEditLink;
