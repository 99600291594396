import { fixInternalExternalDataAttr } from './postMapper';

export default (metaObject) => {
  const result = { ...metaObject };
  if (result.excerpt) {
    result.excerpt = result.excerpt.replace(/<a\s(.*?)>/g, '<a data-link="int" $1 target="_blank" rel="noreferrer">');
    result.excerpt = result.excerpt.replace(/│/g, '');
    result.excerpt = fixInternalExternalDataAttr(result.excerpt);

    if (APP_TYPE === 'app') {
      result.excerpt = result.excerpt.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mwat"');
    } else if (APP_TYPE === 'widget') {
      result.excerpt = result.excerpt.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mpm"');
    }
  }
  if (!result.hasOwnProperty('tags')) result.tags = [];
  if (!result.hasOwnProperty('trending_tags')) result.trending_tags = [];
  return result;
};
