/**
 * Find first bigger image than the containerWidth
 * @returns Object {width, height, url}
 */
export const chooseBestSizeImageUrl = (imageSizes, containerWidth) => {
  return imageSizes.reduce((lastGoodOne, size) => {
    /// take first one as a default
    if (lastGoodOne === null) return size;
    /// negative diff means image will be stretched - NOK
    const currentDiff = size.width - containerWidth;
    const lastGoodOneDiff = lastGoodOne.width - containerWidth;
    /// if lastGoodOne is smaller than zero, and current is bigger, take new one
    if (lastGoodOneDiff < 0 && currentDiff > lastGoodOneDiff) return size;
    return lastGoodOne;
  }, null);
};

/**
 *
 * @param {*} imageSizes
 * @returns Size object {
 *   width,
 *   height,
 *   url,
 * }
 */
export const getHighestResolutionImageObject = (imageSizes) => {
  return imageSizes.reduce((lastGoodOne, size) => {
    if (lastGoodOne === null) return size;
    if (size.width > lastGoodOne.width) return size;
    return lastGoodOne;
  }, null);
};

/**
 * Choose biggest resolution from srcset string
 *
 * @param srcSetString
 * @returns {{}}
 */
export const chooseBiggest = (srcSetString) => {
  if (!srcSetString) {
    return {};
  }
  let sizes = [...srcSetString.matchAll(/http[s]?:.*?w=(\d+)\S*?h=(\d+)\S*/g)].map((part) => ({ src: part[0], width: parseInt(part[1]), height: parseInt(part[2]) }));

  return (
    sizes.reduce((accumulator, image, index) => {
      if (accumulator === null) {
        return image;
      }
      return accumulator.width < image.width ? image : accumulator;
    }, null) || {}
  );
};
