import { chooseBiggest, getHighestResolutionImageObject } from '../../utils/image_sizes';

/**
 * should be called on parent onClick event
 * it needs to be run BEFORE native event to open gallery
 *
 * @param event - onClickEvent
 * @param noLink - it is false for parent's onClick event, and true for ArticleImage
 *
 * @returns {(function(*=, *): (boolean|undefined))|*}
 */
export default function useTryGallery(article, openGallery) {
  return (event, noLink) => {
    if (noLink === true || (event.target.tagName === 'IMG' && event.target.parentElement.tagName === 'A')) {
      let countOfImages = 0;
      let images = [];
      let activeSlide = false;

      if (images.length === 0) {
        let domParser = new DOMParser();
        let contentDOM = domParser.parseFromString(article.excerpt + article.content, 'text/html');

        images = [...contentDOM.querySelectorAll('figure')]
          .map((item) => ({
            img: item.querySelector('img'),
            caption: item.querySelector('figcaption'),
          }))
          .filter((item) => item.img !== null && item.caption !== null)
          .map((item) => {
            countOfImages++;
            return {
              clickedOn: noLink === false ? item.img.getAttribute('src') === event.target.getAttribute('src') : false,
              src: item.img.getAttribute('src'),
              ...chooseBiggest(item.img.getAttribute('srcset')),
              caption: item.caption.innerText,
            };
          });

        const fullSizeImageObject = getHighestResolutionImageObject(article.image.sizes);
        if (fullSizeImageObject) {
          countOfImages++;
          images.push({
            clickedOn: noLink === true,
            src: fullSizeImageObject.url,
            width: fullSizeImageObject.width,
            height: fullSizeImageObject.height,
            caption: article.image.excerpt,
          });
        }
      }

      if (countOfImages > 0) {
        activeSlide = images.reduce((acc, item, i) => {
          if (item.clickedOn === true) return i + 1;
          return acc;
        }, 1);

        openGallery(images, activeSlide);
        if (event) event.preventDefault();
        return false;
      }
    }
  };
}
