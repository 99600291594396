import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCookie from 'react-use-cookie';
import { fetchUserData, manualUpdatesSelector, setManualUpdatesContext, setMeta, setSportsEnabledContext, sportEnabledSelector } from '../Redux/appSlice';
import { useAppContextAppType } from './AppContextProvider';

const UserSettingsContext = React.createContext({});
const useUserSettingsContext = () => useContext(UserSettingsContext);

const UserSettingsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const appType = useAppContextAppType();
  const [appTheme, setAppTheme] = useCookie(ENV_COOKIE_THEME, 'system', { SameSite: 'Lax', Secure: true });
  const [areCookiesEnabled, setCookiesEnabled] = useState(false);
  const manualUpdatesSelected = useSelector(manualUpdatesSelector);
  const sportsEnabledSelected = useSelector(sportEnabledSelector);
  useEffect(() => {
    manualUpdatesSelected !== null && setAutoAddNewPosts(manualUpdatesSelected);
  }, [manualUpdatesSelected]);
  useEffect(() => {
    sportsEnabledSelected !== null && setSportEnabled(sportsEnabledSelected);
  }, [sportsEnabledSelected]);

  const setDarkMode = (darkMode) => {
    if (systemDarkMode) {
      if (darkMode === true) setAppTheme('system', { days: 3650, SameSite: 'Lax', Secure: true });
      else setAppTheme('light', { days: 3650, SameSite: 'Lax', Secure: true });
    } else {
      if (darkMode === true) setAppTheme('dark', { days: 3650, SameSite: 'Lax', Secure: true });
      else setAppTheme('system', { days: 3650, SameSite: 'Lax', Secure: true });
    }
  };

  /// logged in user  ---------------------------------------------------------------
  const [nToken] = useCookie(ENV_COOKIE_N_TOKEN, '', { SameSite: 'Lax', Secure: true });

  useEffect(() => {
    nToken !== '' && dispatch(fetchUserData());
  }, [nToken]);

  /// sports enabled  ---------------------------------------------------------------
  const [sportEnabledCookie, setSportEnabledCookie] = useCookie(ENV_COOKIE_SPORT_ENABLED, 'true', { SameSite: 'Lax', Secure: true });
  const [autoAddNewPostsCookie, setAutoAddNewPostsCookie] = useCookie(ENV_COOKIE_AUTO_ADD_NEW_POSTS, 'false', { SameSite: 'Lax', Secure: true });

  const [systemDarkMode, setSystemDarkMode] = useState(() => {
    try {
      /// SSR will fail on window
      const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)');
      return !!matchedMedia.matches;
    } catch (e) {
      return false;
    }
  });
  const systemColorSchemeListener = useCallback((event) => {
    setSystemDarkMode(!!event.matches);
  }, []);

  /// setter
  const setSportEnabled = (value) => {
    if (value !== sportsEnabledSelected) {
      setSportEnabledCookie(value, { days: 3650, SameSite: 'Lax', Secure: true });
      dispatch(setSportsEnabledContext(value));

      /// if nToken is not empty, user is logged in
      if (nToken !== '') {
        dispatch(setMeta({ nToken: nToken, key: 'sport_enabled', value: value }));
      }
    }
  };

  const setAutoAddNewPosts = (value) => {
    if (value !== manualUpdatesSelected) {
      setAutoAddNewPostsCookie(value, { days: 3650, SameSite: 'Lax', Secure: true });
      dispatch(setManualUpdatesContext(value));

      /// if nToken is not empty, user is logged in
      if (nToken !== '') {
        dispatch(setMeta({ nToken: nToken, key: 'mnt_manual', value: value }));
      }
    }
  };

  /// -------------------------------------------------------------------------------
  const [lastArticleSeen, setLastArticleSeen] = useCookie('lastarticleseen', Date.now(), { SameSite: 'Lax', Secure: true });

  /// cookie - darkmode
  useEffect(() => {
    if (appType === 'widget') return; /// widget does not have dark mode
    if (appTheme === 'dark' || (appTheme === 'system' && systemDarkMode)) {
      document.body.classList.add('mnt-darkTheme');
      if (typeof window.swDarkMode === 'function') window.swDarkMode();
    } else {
      document.body.classList.remove('mnt-darkTheme');
      if (typeof window.swLightMode === 'function') window.swLightMode();
    }
  }, [appTheme, systemDarkMode, appType]);

  useEffect(() => {
    dispatch(fetchUserData());
    if (window.navigator) {
      setCookiesEnabled(window.navigator.cookieEnabled);
    }
    if (window.matchMedia) {
      const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)');
      setSystemDarkMode(!!matchedMedia.matches);
      if (typeof matchedMedia.addEventListener === 'function') matchedMedia.addEventListener('change', systemColorSchemeListener);
      else if (typeof matchedMedia.addListener === 'function') matchedMedia.addListener(systemColorSchemeListener);
      return () => {
        const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)');
        if (typeof matchedMedia.removeEventListener === 'function') matchedMedia.removeEventListener('change', systemColorSchemeListener);
        else if (typeof matchedMedia.removeListener === 'function') matchedMedia.removeListener(systemColorSchemeListener);
      };
    }
  }, []);

  /// first time visitor  ---------------------------------------------------------------
  const [seenWhatsNewModal, setSeenWhatsNewModal] = useCookie(ENV_SEEN_WHATS_NEW_MODAL, 'true', { SameSite: 'Lax', Secure: true });

  return (
    <UserSettingsContext.Provider
      value={{
        isDarkMode: appTheme === 'dark' || (appTheme === 'system' && systemDarkMode),
        setDarkMode: setDarkMode,
        sportEnabled: sportsEnabledSelected !== null ? sportsEnabledSelected : sportEnabledCookie === 'true',
        setSportEnabled: setSportEnabled,
        autoAddNewPosts: manualUpdatesSelected !== null ? manualUpdatesSelected : autoAddNewPostsCookie === 'true',
        setAutoAddNewPosts: setAutoAddNewPosts,
        lastArticleSeen: Math.floor(lastArticleSeen),
        setLastArticleSeen: setLastArticleSeen,
        showWhatsNew: areCookiesEnabled && seenWhatsNewModal !== 'true',
        setSeenWhatsNew: () => setSeenWhatsNewModal('true', { SameSite: 'Lax', Secure: true }),
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useAutoAddNewPostsSetting = () => {
  const ctx = useUserSettingsContext();
  return ctx.autoAddNewPosts;
};

const withUserSettingsContext = (Component) => (props) => {
  const value = useUserSettingsContext();
  return <Component {...props} {...value} />;
};

export default UserSettingsContextProvider;
export { useUserSettingsContext, withUserSettingsContext };
