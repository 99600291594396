import React, { useCallback, useEffect, useRef, useState } from 'react';
import Menu from '../Sections/Menu';
import Toolbar from '../Sections/Toolbar';
import Aside from '../Sections/Aside';
import { useDispatch, useSelector } from 'react-redux';
import { closeMobileMenu, isMobileMenuOpenedSelector } from '../../Redux/appSlice';
import SpriteMap from '../../Components/SpriteMap';
import Ticker from '../../Components/Controllers/Ticker';
import { fetchSetup, lastTimestampSelector, featureSelector } from '../../Redux/setupSlice';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { withNetworkState } from '../../ContextProviders/NetworkStateContextProvider';
import SWLink from '../../Components/SWLink';
import GalleryContextProvider from '../../ContextProviders/GalleryContextProvider';
import ToastContextProvider from '../../ContextProviders/ToastContextProvider';
import smoothscroll from 'smoothscroll-polyfill';
import OfflineToastController from '../../Components/Controllers/OfflineToastController';
import ScrollingHelperContextProvider from '../../ContextProviders/ScrollingHelperContext';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import RempContextProvider from '../../ContextProviders/RempContextProvider';
import GTMTrackerContextProvider from '../../ContextProviders/GTMTrackerContextProvider';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ children, messages }) => {
  const location = useLocation();
  const scrollListenerRef = useRef(null);
  const dispatch = useDispatch();
  const isMenuOpened = useSelector(isMobileMenuOpenedSelector);
  const lastTimestamp = useSelector(lastTimestampSelector);
  const feature = useSelector(featureSelector);
  const hasFeature = feature && feature.url && feature.height > 0;

  const [lastScrollTop, setNewPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('still');

  const onTickCallback = useCallback(() => {
    dispatch(fetchSetup());
  }, []);

  const scrollListener = () => {
    const currentTop = window.scrollY;
    if (currentTop < 100) {
      setScrollDirection('up');
      return;
    }
    if (Math.abs(currentTop - lastScrollTop) > 30) {
      setScrollDirection(currentTop > lastScrollTop ? 'down' : 'up');
      setNewPosition(currentTop);
    }
  };

  useEffect(() => {
    if (location.key === undefined) {
      return;
    }
    window.pp_gemius_hit && window.pp_gemius_hit(ENV_GEMIUS_ID, '');
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    if (isMenuOpened) {
      document.body.classList.add('mnt-BodyOffset');
      return;
    }
    document.body.classList.remove('mnt-BodyOffset');
  }, [isMenuOpened]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div>
      <Ticker lastTimestamp={lastTimestamp} timeoutAfterSeconds={ENV_SETUP_TICKER_SPEED} onlyOnce={false} onTick={onTickCallback} skipFirst={false} tickerSpeedSeconds={ENV_SETUP_TICKER_SPEED} fireOnChange={[]} />

      <RempContextProvider>
        <GTMTrackerContextProvider>
          <ScrollingHelperContextProvider appType={'app'}>
            <div className={'mnt-Minuta'}>
              <ToastContextProvider>
                <GalleryContextProvider>
                  <div className={'mnt-Layout mnt-layout--app'}>
                    <OfflineToastController />
                    <div className={'mnt-layout__toolbar' + (scrollDirection === 'down' ? ' mnt-scrolling' : '')}>
                      <Toolbar />
                    </div>
                    <div className={'mnt-layout__container mnt-layout__content'} style={hasFeature && { paddingTop: `${feature.height + 55}px` }}>
                      <div className={'mnt-feature__container'} style={hasFeature && { marginTop: `-${feature.height}px` }}>
                        {feature && feature.url && feature.height > 0 && <iframe src={feature.url} width="100%" height={hasFeature && `${feature.height}px`}></iframe>}
                      </div>
                      <div className={'mnt-layout__menu ' + (isMenuOpened ? 'open' : '')} style={hasFeature && { top: `${feature.height + 55}px` }}>
                        <Menu closeMobileMenu={() => dispatch(closeMobileMenu())} isMobileMenuOpened={isMenuOpened} />
                      </div>
                      <div className={'mnt-overlay__menu'} />
                      <div className={'mnt-layout__content-feed mnt-js-scrolling-element'} ref={scrollListenerRef} key={null}>
                        {children}
                      </div>
                      <div className={'mnt-layout__content-aside'} style={hasFeature && { top: `${feature.height + 55}px` }}>
                        <Aside />
                      </div>
                      {APP_LANG === 'sk' && (
                        <div className={'mnt-layout__bottom-toolbar-sk'}>
                          <a href={messages.linkToDennikN} title={messages.dennikN} className={'mnt-logoN'}>
                            {messages.dennikN}
                            <SpriteMap sprite={'logo_N'} />
                          </a>
                          <SWLink to={ENV_APP_HOMEPAGE_ROUTE} className={'mnt-logoMnt'}>
                            <span className={`mnt-logo mnt-logo-${APP_LANG}`}>
                              <SpriteMap sprite={`logo_mnt-${APP_LANG}`} />
                            </span>
                            <span className={'mnt-logoTitle'} dangerouslySetInnerHTML={{ __html: messages.logoTextHtml }}></span>
                          </SWLink>
                        </div>
                      )}
                      {APP_LANG === 'cz' && (
                        <div className={'mnt-layout__bottom-toolbar-cz'}>
                          <a href={messages.linkToDennikN} title={messages.dennikN} className={`mnt-logoN mnt-logoN-cz`}>
                            {messages.dennikN}
                            <SpriteMap sprite={'logo_N'} />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </GalleryContextProvider>
              </ToastContextProvider>

              {/* {showWhatsNew && ( <div className={'mnt-ModalWrapper'}>
                  <div className={'mnt-Modal'}>
                    <ModalTheme />
                  </div>
                </div>
              )} */}
            </div>
          </ScrollingHelperContextProvider>
        </GTMTrackerContextProvider>
      </RempContextProvider>
    </div>
  );
};

export default withUserSettingsContext(withNetworkState(withMessages(MainLayout)));
