const fetch = require('cross-fetch');

/*******************************************************************************
 * API definitions
 * -----------------------------------------------------------------------------
 * - env.API_HOST - should contain hostname with trailing slash
 ******************************************************************************/

///
/// Setup API ------------------------------------------------------------------
///

const SETUP_API_URL = ENV_API_URL_SETUP;
export const SETUP_MINIMAL_CACHE_TIME_SECONDS = 5 * 60;

/// USECASE - simply call Setup api and return JSON/catch exception
export async function executeSetupCall() {
  const timestamp = Date.now();
  const result = await executeApiCallWithCookies(ENV_API_HOST + SETUP_API_URL);
  result.timestamp = timestamp;
  return result;
}

///
/// Posts API ------------------------------------------------------------------
///

const POSTS_API_URL = ENV_API_URL_POSTS;

/// USECASE - call Posts with custom params
export async function executePostsCall(params) {
  const timestamp = Date.now();
  const result = await executeApiCall(ENV_API_HOST + POSTS_API_URL + buildQueryParams(params));
  result.timestamp = timestamp;
  return result;
}

export function postParamsFromMatch(routeMatch) {
  console.log(JSON.stringify(routeMatch));
  return {};
}

///
/// Reader/posts API -----------------------------------------------------------
///

const READER_POSTS_API_URL = ENV_API_URL_READER_POSTS;
export const READER_POSTS_MINIMAL_CACHE_TIME_SECONDS = 5 * 60;

/// USECASE - call Reader/Posts with custom params
export async function executeReaderCall(params) {
  params['post_type'] = 'post';
  return await executeApiCall(ENV_API_HOST + READER_POSTS_API_URL + buildQueryParams(params));
}

///
/// Search API ------------------------------------------------------------------
///

const SEARCH_API_URL = ENV_API_URL_SEARCH;

/// USECASE - call Search with custom params
export async function executeSearchCall(params) {
  return await executeApiCall(ENV_API_HOST + SEARCH_API_URL + buildQueryParams(params));
}

///
/// Lives Archive----------------------------------------------------------------
///

const LIVE_ARCHIVE_API_URL = ENV_API_URL_LIVES;

export async function executeLiveArchiveCall(params) {
  const timestamp = Date.now();
  const result = await executeApiCall(ENV_API_HOST + LIVE_ARCHIVE_API_URL + buildQueryParams(params));
  result.timestamp = timestamp;
  return result;
}

///
/// UsersMeta API ---------------------------------------------------------------
///

/// USECASE - as for now, it is used only for sport_enabled variable
export async function executeUserMetaCall(nToken) {
  try {
    const request = await fetch(ENV_API_HOST_USERMETA + ENV_API_URL_USERMETA_LIST, {
      method: 'GET',
      headers: {
        // eslint-disable-next-line prettier/prettier
        Authorization: `Bearer ${nToken}`,
        // eslint-disable-next-line prettier/prettier
        Accept: 'application/json',
      },
    });
    if (request.status === 200) {
      return request.json();
    }
    throw { error: 'request failed with status ' + request.status };
  } catch (ex) {
    throw { error: ex.message };
  }
}

export async function executePostUserMetaCall(nToken, key, value) {
  try {
    const formData = new URLSearchParams();
    formData.append('key', key);
    formData.append('value', value);
    const request = await fetch(ENV_API_HOST + ENV_API_URL_USERMETA_UPSERT, {
      method: 'POST',
      headers: {
        // eslint-disable-next-line prettier/prettier
        Authorization: `Bearer ${nToken}`,
        // eslint-disable-next-line prettier/prettier
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    });
    if (request.status === 200) {
      return await request.json();
    }
    throw { error: 'request failed with status ' + request.status };
  } catch (ex) {
    throw { error: ex.message };
  }
}

export async function executeUserDataCall() {
  return await executeApiCallWithCookies(ENV_USER_DATA_URL);
}

///
///
/// Helper functions -----------------------------------------------------------
///
///

/**
 * orders paramsJson and generates GET params string
 *
 * @param {*} paramsJson
 * @returns string
 */
function buildQueryParams(paramsJson) {
  const params = replaceSlugForId(paramsJson);
  const keys = Object.keys(params);
  if (keys.length === 0) return '';
  keys.sort();
  const orderedParams = keys.reduce((acc, it) => ({ ...acc, [it]: params[it] }), {});
  return '?' + new URLSearchParams(orderedParams);
}

function replaceSlugForId(paramsJson) {
  /// if there is no ID, we have nothing to do in here.
  if (!paramsJson.hasOwnProperty('id')) return paramsJson;
  /// list of attributes that can be switched from slug to ID
  const attribsThatCanBeReplaced = ['category', 'tag'];
  const fixedParams = { ...paramsJson };
  /// find possible candidate
  const attribToReplace = attribsThatCanBeReplaced.filter((it) => paramsJson.hasOwnProperty(it));
  /// there can be ONLY 1
  if (attribToReplace.length === 1) {
    fixedParams[attribToReplace[0]] = fixedParams.id;
    delete fixedParams.id;
  }
  return fixedParams;
}

/**
 * executes fetch
 *
 * @param {*} url
 * @returns reponsnse JSON
 */
async function executeApiCall(url) {
  try {
    const request = await fetch(url);
    if (request.status === 200) {
      return request.json();
    }
    throw { error: 'request failed with status ' + request.status };
  } catch (ex) {
    throw { error: ex.message };
  }
}

/**
 * executes fetch, with cookies
 *
 * @param {*} url
 * @returns reponsnse JSON
 */
async function executeApiCallWithCookies(url) {
  try {
    const request = await fetch(url, {
      credentials: 'include',
    });
    if (request.status === 200) {
      return request.json();
    }
    throw { error: 'request failed with status ' + request.status };
  } catch (ex) {
    throw { error: ex.message };
  }
}
