import React, { memo } from 'react';

const ShareViaEmail = ({ url, content, afterClick, children }) => {
  const domParser = new DOMParser();

  let title = content.match(/<a.*?>(.*?)<\/a>/);
  if (title === null) {
    title = content.match(/<strong.*?>(.*?)<\/strong>/);
  }
  if (title !== null) {
    title = domParser.parseFromString(title[1], 'text/html');
  }
  let body = domParser.parseFromString(content, 'text/html');

  const emailUrl = 'mailto: ?subject=' + encodeURI(title.body.textContent) + '&body=' + encodeURI(body.body.textContent) + '%0A' + url + '?ref=pop';

  const onClick = () => {
    afterClick && afterClick();
  };

  return (
    <a href={emailUrl} onClick={onClick} target={'_blank'} rel="noreferrer">
      {children}
    </a>
  );
};

export default ShareViaEmail;
