import React, { useContext } from 'react';
import messagesSK from './../language_sk';
import messagesCZ from './../language_cz';

const LanguageContext = React.createContext({});
const useLanguageContext = () => useContext(LanguageContext);

/// Wrap application / widget to provide localized messages to its children components
const LanguageContextProvider = ({ children }) => {
  return <LanguageContext.Provider value={APP_LANG === messagesSK.shortCode ? messagesSK : messagesCZ}>{children}</LanguageContext.Provider>;
};

/// HigherOrder component that will inject messages to Component.props
const withMessages = (Component) => (props) => {
  const messages = useLanguageContext();

  return (
    /// adds messages property to the <Component
    <Component {...props} messages={messages} />
  );
};

export default LanguageContextProvider;
export { useLanguageContext, withMessages };
