import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appInBackground, appInForeground, totalInactiveTimeSelector, appIsActiveSelector } from '../Redux/appSlice';
import { hasHistorySelector } from '../Redux/postsSlice';

const AppContext = React.createContext({});
const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ appType, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const totalInactiveTime = useSelector(totalInactiveTimeSelector);
  const appIsActiveState = useSelector(appIsActiveSelector);
  const hasHistory = useSelector(hasHistorySelector);
  const [isMobile, setIsMobile] = useState(true);
  const [isTablet, setIsTablet] = useState(true);

  const appIsInBackground = () => {
    dispatch(appInBackground());
  };

  const appIsActive = () => {
    dispatch(appInForeground());
  };

  const resizeEventListener = useCallback(() => {
    try {
      if (window && window.innerWidth > 960) {
        setIsTablet(false);
        setIsMobile(false);
        return;
      }
      if (window && window.innerWidth > 768) {
        setIsMobile(false);
        return;
      }
      if (window && window.innerWidth <= 960 && window.innerWidth > 768) {
        setIsTablet(true);
      }
    } catch (ex) {}
    setIsMobile(true);
  }, []);

  /**
   * Tab inactivity
   */
  useEffect(() => {
    var hidden, visibilityChange;

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (document && typeof document.addEventListener !== 'undefined' && hidden !== undefined) {
      document.addEventListener(visibilityChange, () => {
        if (document[hidden]) appIsInBackground();
        else appIsActive();
      });
      resizeEventListener();
      window.addEventListener('resize', resizeEventListener);
      return () => window.removeEventListener('resize', resizeEventListener);
    }
  }, []);

  useEffect(() => {
    // const routeMatch = matchUrl(location.pathname);
    setCanGoBack(hasHistory);
  }, [location, setCanGoBack]);

  return <AppContext.Provider value={{ appType: appType, totalInactiveTime: totalInactiveTime, appIsActiveState: appIsActiveState, canGoBack: canGoBack, isMobile: isMobile, isTablet: isTablet }}>{children}</AppContext.Provider>;
};

const withAppContext = (Component) => (props) => {
  const value = useAppContext();
  return <Component {...props} appType={value.appType} totalInactiveTime={value.totalInactiveTime} canGoBack={value.canGoBack} appIsActiveState={value.appIsActiveState} />;
};

const useAppContextIsMobile = () => {
  const ctx = useAppContext();
  return ctx.isMobile;
};

const useAppContextIsTablet = () => {
  const ctx = useAppContext();
  return ctx.isTablet;
};

const useAppContextAppType = () => {
  const ctx = useAppContext();
  return ctx.appType;
};

export default AppContextProvider;
export { useAppContext, withAppContext, useAppContextIsMobile, useAppContextIsTablet, useAppContextAppType };
