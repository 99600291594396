import React, { useEffect } from 'react';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { withNetworkState } from '../../ContextProviders/NetworkStateContextProvider';
import { withToast } from '../../ContextProviders/ToastContextProvider';

const OfflineToastController = ({ isOnline, showToast, messages }) => {
  useEffect(() => {
    isOnline === false && showToast(messages.appIsOffline);
  }, [isOnline]);

  return <></>;
};

export default withToast(withMessages(withNetworkState(OfflineToastController)));
