import { configureStore } from '@reduxjs/toolkit';
import setupReducer from './setupSlice';
import postsReducer from './postsSlice';
import readerReducer from './readerSlice';
import searchReducer from './searchSlice';
import livesReducer from './livesSlice';
import appReducer from './appSlice';

const storeConfig = {
  reducer: {
    setup: setupReducer,
    posts: postsReducer,
    reader: readerReducer,
    search: searchReducer,
    lives: livesReducer,
    app: appReducer,
  },
  devTools: ENV_DEV_TOOLS_ENABLED,
};

try {
  if (window !== undefined && window.__INITIAL_STATE__) {
    storeConfig.preloadedState = window.__INITIAL_STATE__;
    delete window.__INITIAL_STATE__;
  }
} catch (ex) {}

export default () => configureStore(storeConfig);
