import React, { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import SWLink from './SWLink';

const LiveLink = ({ id, slug, children, messages }) => {
  const url = generatePath(ENV_APP_LIVE_ROUTE, { id: id, live: slug });

  return (
    <div className={'mnt-LiveLink mnt-space-btw'}>
      <SWLink to={url}>{children}</SWLink>
      <span className={'mnt-live'}>{messages.liveBadge}</span>
    </div>
  );
};

export default withMessages(memo(LiveLink));
