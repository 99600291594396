import React from 'react';
import PropTypes from 'prop-types';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import SpriteMap from './SpriteMap';
import { withGTMMTrackerContext } from '../ContextProviders/GTMTrackerContextProvider';

const PendingPostsCTA = ({ onClick, countOfPendingPosts, messages, trackFetchNewPosts }) => {
  const postsPendingMessage = messages.newPostsPendingFn(countOfPendingPosts);
  const onLoadPendingClick = () => {
    trackFetchNewPosts();
    onClick();
  };

  return (
    <div className={'mnt-PendingPostsCTA'}>
      <button onClick={onLoadPendingClick} aria-label={postsPendingMessage}>
        <SpriteMap sprite={'expand_all'} />
        {postsPendingMessage}
      </button>
    </div>
  );
};

PendingPostsCTA.propTypes = {
  onClick: PropTypes.func.isRequired,
  countOfPendingPosts: PropTypes.number.isRequired,
};

export default withGTMMTrackerContext(withMessages(PendingPostsCTA));
