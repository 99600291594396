import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { setCookie } from 'react-use-cookie';
import { executePostUserMetaCall, executeUserDataCall, executeUserMetaCall } from '../api_definitions';

export const fetchMeta = createAsyncThunk('app/fetchMeta', async ({ nToken }, { rejectWithValue }) => {
  try {
    return await executeUserMetaCall(nToken);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const setMeta = createAsyncThunk('app/setMeta', async ({ nToken, key, value }, { rejectWithValue }) => {
  try {
    return await executePostUserMetaCall(nToken, key, value === true ? '1' : '0');
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const fetchUserData = createAsyncThunk('app/fetchUserData', async (payload, { rejectWithValue }) => {
  try {
    return await executeUserDataCall();
  } catch (ex) {
    return rejectWithValue({ error: 'not found' });
  }
});

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    userDataIsLoading: false,
    userDataError: false,
    userDataRequestPassed: false,
    userData: {},

    showShareMenuForArticleId: 0,
    isMobileMenuOpened: false,
    appIsActive: true,
    appInactiveSince: 0,
    totalInactiveTime: 0,

    userMeta: [],
    userId: 0,
    sportEnabled: null,
    manualUpdates: null,
    fetchMetaIsLoading: false,
    fetchMetaTimestamp: 0,
    fetchMetaError: null,
    fetchMetaSuccessFlag: false,

    setMetaIsLoading: false,
    setMetaTimestamp: 0,
    setMetaError: null,
  },
  reducers: {
    toggleShareMenuForArticle: (state, { payload }) => {
      if (state.showShareMenuForArticleId === payload.articleId) {
        state.showShareMenuForArticleId = 0;
      } else {
        state.showShareMenuForArticleId = payload.articleId;
      }
    },
    toggleMobileMenu: (state) => {
      state.isMobileMenuOpened = !state.isMobileMenuOpened;
    },
    closeShareMenu: (state) => {
      if (state.showShareMenuForArticleId !== null) {
        state.showShareMenuForArticleId = null;
      }
    },
    closeMobileMenu: (state) => {
      state.isMobileMenuOpened = false;
    },
    appInBackground: (state) => {
      state.appInactiveSince = new Date() / 1000;
      state.totalInactiveTime = 0;
      state.appIsActive = false;
    },
    appInForeground: (state) => {
      state.appIsActive = true;
      state.totalInactiveTime = new Date() / 1000 - state.appInactiveSince;
      state.appInactiveSince = 0;
    },
    resetInactiveTime: (state) => {
      state.totalInactiveTime = 0;
    },
    setSportsEnabledContext: (state, { payload }) => {
      state.sportEnabled = payload;
    },
    setManualUpdatesContext: (state, { payload }) => {
      state.manualUpdates = payload;
    },
  },
  extraReducers: {
    [fetchMeta.pending]: (state) => {
      state.fetchMetaIsLoading = true;
      state.fetchMetaError = null;
    },
    [fetchMeta.rejected]: (state, { payload }) => {
      state.fetchMetaTimestamp = Math.floor(Date.now() / 1000);
      state.fetchMetaIsLoading = false;
      state.fetchMetaError = payload;
    },
    [fetchMeta.fulfilled]: (state, { payload }) => {
      state.userMeta = payload;

      payload.forEach((it) => {
        state.userId = it.user_id;
        if (it.key === 'sport_enabled') {
          state.sportEnabled = it.value === '1';
          state.fetchMetaSuccessFlag = !state.fetchMetaSuccessFlag;
          setCookie(ENV_COOKIE_SPORT_ENABLED, it.value === '1', { days: 3650, SameSite: 'Lax', Secure: true });
        } else if (it.key === 'mnt_manual') {
          state.manualUpdates = it.value === '1';
          /// we have opposite use of the same config value, that's why we are checking for 0 to set it true
          setCookie(ENV_COOKIE_AUTO_ADD_NEW_POSTS, it.value === '1', { days: 3650, SameSite: 'Lax', Secure: true });
        }
      });

      state.fetchMetaTimestamp = Math.floor(Date.now() / 1000);
      state.fetchMetaIsLoading = false;
      state.fetchMetaError = null;
    },

    [setMeta.pending]: (state) => {
      state.setMetaIsLoading = true;
      state.setMetaError = null;
    },
    [setMeta.rejected]: (state, { payload }) => {
      state.setMetaTimestamp = Math.floor(Date.now() / 1000);
      state.setMetaIsLoading = false;
      state.setMetaError = payload;
    },
    [setMeta.fulfilled]: (state, { payload }) => {
      state.userMeta = payload;
      if (payload.key === 'sport_enabled') {
        state.sportEnabled = payload.value === '1';
        state.fetchMetaSuccessFlag = !state.fetchMetaSuccessFlag;
        setCookie(ENV_COOKIE_SPORT_ENABLED, payload.sport_enabled === '1', { days: 3650, SameSite: 'Lax', Secure: true });
      }
      if (payload.key === 'mnt_manual') {
        state.manualUpdates = payload.value === '1';
        setCookie(ENV_COOKIE_AUTO_ADD_NEW_POSTS, payload.value === '1', { days: 3650, SameSite: 'Lax', Secure: true });
      }
      state.setMetaTimestamp = Math.floor(Date.now() / 1000);
      state.setMetaIsLoading = false;
      state.setMetaError = null;
    },

    [fetchUserData.pending]: (state) => {
      state.userDataIsLoading = true;
      state.userDataError = false;
    },
    [fetchUserData.fulfilled]: (state, { payload }) => {
      state.userDataIsLoading = false;
      state.userDataError = false;
      state.userData = payload;
      window.dnru = payload;
      state.userDataRequestPassed = true;

      if (payload.basic) {
        state.userId = payload.basic.id;
      } else {
        state.userId = 0;
      }
      if (payload.user_meta) {
        payload.user_meta.forEach((it) => {
          if (Object.keys(it)[0] === 'sport_enabled') {
            state.sportEnabled = it.sport_enabled === '1';
            setCookie(ENV_COOKIE_SPORT_ENABLED, it.sport_enabled === '1', { days: 3650, SameSite: 'Lax', Secure: true });
          } else if (Object.keys(it)[0] === 'mnt_manual') {
            state.manualUpdates = it.mnt_manual === '1';
            setCookie(ENV_COOKIE_AUTO_ADD_NEW_POSTS, it.mnt_manual === '1', { days: 3650, SameSite: 'Lax', Secure: true });
          }
        });
      }
    },
    [fetchUserData.rejected]: (state) => {
      state.userDataIsLoading = false;
      state.userDataError = true;
      window.dnru = {};
      state.userDataRequestPassed = true;
    },
  },
});

const appStateSelector = (state) => state.app;
export const shareMenuVisibleForArticleIdSelector = createSelector(appStateSelector, (state) => state.showShareMenuForArticleId);
export const isMobileMenuOpenedSelector = createSelector(appStateSelector, (state) => state.isMobileMenuOpened);
export const totalInactiveTimeSelector = createSelector(appStateSelector, (state) => state.totalInactiveTime);
export const appIsActiveSelector = createSelector(appStateSelector, (state) => state.appIsActive);
export const sportEnabledSelector = createSelector(appStateSelector, (state) => state.sportEnabled);
export const manualUpdatesSelector = createSelector(appStateSelector, (state) => state.manualUpdates);
export const hasMetaErrorSelector = createSelector(appStateSelector, (state) => state.setMetaError);
export const hasFetchMetaErrorSelector = createSelector(appStateSelector, (state) => state.fetchMetaError);
export const fetchMetaSuccessFlagSelector = createSelector(appStateSelector, (state) => state.fetchMetaSuccessFlag);

export const userIdSelector = createSelector(appStateSelector, (state) => state.userId);
export const userMetaSelector = createSelector(appStateSelector, (state) => state.userMeta);
export const userDataRequestPassedSelector = createSelector(appStateSelector, (state) => state.userDataRequestPassed);
export const userDataSelector = createSelector(appStateSelector, (state) => state.userData);
export const userSubscribedTypesSelector = createSelector(appStateSelector, (state) => {
  if (state.userData.hasOwnProperty('subscriptions')) {
    let types = state.userData.subscriptions.reduce((acc, subscription) => [...acc, ...subscription.types], []);
    return [...new Set(types)];
  }
  return [];
});

export const userSubscriptionsSelector = createSelector(appStateSelector, (state) => state.userData.subscriptions || []);

export const {
  //
  toggleShareMenuForArticle,
  toggleMobileMenu,
  closeShareMenu,
  closeMobileMenu,
  appInBackground,
  appInForeground,
  resetInactiveTime,
  setSportsEnabledContext,
  setManualUpdatesContext,
} = appSlice.actions;

export default appSlice.reducer;
