import React from 'react';

const ShareToWhatsApp = ({ url, afterClick, children }) => {
  const whatsAppUrl = 'https://wa.me/?text=' + url;

  const onClick = () => {
    afterClick && afterClick();
  };

  return (
    <a href={whatsAppUrl} onClick={onClick} target={'_blank'} rel="noreferrer">
      {children}
    </a>
  );
};

export default ShareToWhatsApp;
