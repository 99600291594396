import React, { memo } from 'react';

const ShareLiveViaEmail = ({ url, name, excerpt, afterClick, children }) => {
  const domParser = new DOMParser();

  let emailUrl = 'mailto: ?subject=' + encodeURI(name) + '&body=';
  if (excerpt) {
    let body = domParser.parseFromString(excerpt, 'text/html');
    if (body && body.body) emailUrl += '&body=' + encodeURI(body.body.textContent);
  }
  emailUrl += '%0A' + url + '?ref=pop';

  const onClick = () => {
    afterClick && afterClick();
  };

  return (
    <a href={emailUrl} onClick={onClick} target={'_blank'} rel="noreferrer">
      {children}
    </a>
  );
};

export default ShareLiveViaEmail;
