import { useContext, useEffect } from 'react';
import { ContinueOnMntContext } from '../ContinueOnMntContextProvider';
import { useLocation } from 'react-router-dom';
import { matchUrl } from '../../Containers/Routes';
import { useLanguageContext } from '../../ContextProviders/LanguageContextProvider';

export default function useContinueOnMnt() {
  const ctx = useContext(ContinueOnMntContext);
  const messages = useLanguageContext();
  const location = useLocation();

  useEffect(() => {
    const routeMatch = matchUrl(location.pathname, 'widget');
    if (routeMatch.routeName === 'LIVE') {
      ctx.setPathname(location.pathname === '/' ? '' : location.pathname);
      ctx.setButtonLabel(messages.readMoreLiveOnMpm);
    } else {
      ctx.setPathname(location.pathname === '/' ? '' : location.pathname);
      ctx.setButtonLabel(messages.readMoreOnMpm);
    }
  }, [location]);
}
