import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userDataSelector } from '../Redux/appSlice';
import { useAppContextAppType } from './AppContextProvider';

const RempContext = React.createContext({});
const useRempContext = () => useContext(RempContext);

const RempContextProvider = ({ children }) => {
  const appType = useAppContextAppType();
  const userData = useSelector(userDataSelector);

  const subscribedTypes = () => {
    if (window.dnru && window.dnru.subscriptions) {
      if (window.dnru.subscriptions.length === 0) return [];
      const now = Date.now() / 1000;
      const subscribedToTypes = window.dnru.subscriptions
        /// filter only active subscriptions
        .filter((sub) => sub.start_time < now && sub.end_time > now)
        /// flatten arrays of Types
        .reduce((acc, subscription) => [...acc, ...subscription.types], []);
      return [...new Set(subscribedToTypes)];
    }
    return [];
  };

  const hasSubscription = () => {
    if (window.dnru && window.dnru.subscriptions) {
      if (window.dnru.subscriptions.length === 0) return false;
      const now = Date.now() / 1000;

      const active = window.dnru.subscriptions.filter((sub) => sub.start_time < now && sub.end_time > now).length;
      return active > 0;
    }
    return null;
  };

  const subscribedIds = () => {
    if (window.dnru && window.dnru.subscriptions) {
      if (window.dnru.subscriptions.length === 0) return [];

      const now = Date.now() / 1000;
      const subscribedToIds = window.dnru.subscriptions
        /// filter only active subscriptions
        .filter((sub) => sub.start_time < now && sub.end_time > now)
        /// flatten arrays of IDs
        .reduce((acc, subscription) => [...acc, '' + subscription.id], []);
      return [...new Set(subscribedToIds)];
    }
    return [];
  };

  const rempConfigSetup = {
    token: ENV_REMP_TOKEN,
    cookieDomain: ENV_REMP_COOKIE_DOMAIN,
    userSubscribed: false,
    tracker: {
      url: ENV_REMP_TRACKER_URL,
      timeSpentEnabled: true,
    },
  };

  const initializeRemp = (callback) => {
    if (ENV_REMP_ENABLED && window.remplib === undefined) {
      initializeRempLib();
    }
  };

  const initializeRempLib = () => {
    (function (win, doc) {
      function mock(fn) {
        return function () {
          this._.push([fn, arguments]);
        };
      }

      function load(url) {
        var script = doc.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        doc.getElementsByTagName('head')[0].appendChild(script);
      }

      win.remplib = win.remplib || {};
      var mockFuncs = {
        campaign: 'init',
        tracker: 'init trackEvent trackPageview trackCommerce trackTimespent',
        iota: 'init',
      };

      Object.keys(mockFuncs).forEach(function (key) {
        if (!win.remplib[key]) {
          var fn,
            i,
            funcs = mockFuncs[key].split(' ');
          win.remplib[key] = { _: [] };

          for (i = 0; i < funcs.length; i++) {
            fn = funcs[i];
            win.remplib[key][fn] = mock(fn);
          }
        }
      });

      load(ENV_CAMPAIGN_LIB_SRC);
      load(ENV_REMP_LIB_SRC);
    })(window, document);
  };

  const track = (config) => {
    if (appType === 'feed') return;

    if (window.remplib === undefined || window.dnru === undefined) {
      setTimeout(() => track(config), 500);
      return;
    }
    if (window.dnru.basic) {
      if (window.dnru.basic.id) config.userId = '' + window.dnru.basic.id;
      config.subscriptionIds = subscribedIds();
      config.userSubscribed = hasSubscription() === true;
    }
    window.remplib.tracker.init(config);
    if (APP_TYPE === 'app') window.remplib.campaign.init(config);
  };

  const trackLive = (id, author, tags, category) => {
    if (ENV_REMP_ENABLED && appType !== 'feed') {
      let rempConfig = {
        ...rempConfigSetup,
        tracker: {
          ...rempConfigSetup.tracker,
          refererMedium: appType === 'app' ? 'mpm_mnt' : 'mpm_widget',
        },
        article: {
          id: 'dn_live-' + id,
          content_type: 'dn_live',
          author_id: author,
          tags: tags,
          category: '' + category,
          locked: false,
          elementFn: () => document.querySelector('.mnt-Feed'),
        },
        campaign: {
          url: 'https://campaign.dennikn.sk',
          pageviewAttributes: {
            article: '1', // povinne – 0 ak ide o bezny feed, 1 ak ide o detail minuty alebo zivy prenos
            locked: '0', // povinne – minuty su vsetky odomknute, cize vzdy 0
            type: 'dn_live', // optional – dn_mpm ak ide o detail minuty alebo dn_live pre zivy prenos, inak tento kluc neposielat
            access: subscribedTypes(), // pole pristupov
          },
        },
      };
      track(rempConfig);
    }
  };

  const stopTracking = () => {
    if (ENV_REMP_ENABLED) {
      let rempConfig = {
        ...rempConfigSetup,
        campaign: {
          url: 'https://campaign.dennikn.sk',
          pageviewAttributes: {
            article: '0', // povinne – 0 ak ide o bezny feed, 1 ak ide o detail minuty alebo zivy prenos
            locked: '0', // povinne – minuty su vsetky odomknute, cize vzdy 0
            access: subscribedTypes(), // pole pristupov
          },
        },
      };
      if (userData.basic) {
        if (userData.basic.id) rempConfig.userId = '' + userData.basic.id;
        rempConfig.subscriptionIds = subscribedIds();
        rempConfig.userSubscribed = hasSubscription() === true;
      }
      track(rempConfig);
    }
  };

  useEffect(() => {
    initializeRemp();
  }, []);

  return <RempContext.Provider value={{ trackLive: trackLive, stopTracking: stopTracking }}>{children}</RempContext.Provider>;
};

const withRempContext = (Component) => (props) => {
  const value = useRempContext();
  return <Component {...props} trackLive={value.trackLive} stopTracking={value.stopTracking} />;
};

export default RempContextProvider;
export { useRempContext, withRempContext };
