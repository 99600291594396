import { useEffect, useState } from 'react';
import { useAppContextAppType, useAppContextIsMobile, useAppContextIsTablet } from '../../ContextProviders/AppContextProvider';
import { useAutoAddNewPostsSetting } from '../../ContextProviders/UserSettingsContextProvider';

const defaultConfig = {
  autoAddNewPosts: true,
  autoAddNewLives: true,
  lazyLoad: true,
  allowExpandedPosts: true,
  showGallery: true,
  showTags: true,
  showEmbed: true,
};

function getConfig(appType, isMobile, isTablet, autoAddNewPostsCookie) {
  if (appType === 'app' || appType === 'feed') {
    return {
      ...defaultConfig,
      autoAddNewPosts: !isMobile, /// mobile is using manual type
    };
  } else if (appType === 'widget') {
    /// Widget has configurable autoAddNewPosts feature
    if (isMobile || isTablet) {
      return {
        ...defaultConfig,
        autoAddNewPosts: autoAddNewPostsCookie,
      };
    } else {
      return {
        ...defaultConfig,
        lazyLoad: false,
        autoAddNewPosts: autoAddNewPostsCookie,
        allowExpandedPosts: false,
        showEmbed: true,
        showGallery: false,
        showTags: false,
      };
    }
  }
}

export default function useFeedConfiguration() {
  const isMobile = useAppContextIsMobile();
  const isTablet = useAppContextIsTablet();
  const appType = useAppContextAppType();
  const autoAddNewPostsCookie = useAutoAddNewPostsSetting();
  const [variant, setVariant] = useState();

  const [currentConfiguration, setConfiguration] = useState(() => getConfig(appType, isMobile, isTablet));

  useEffect(() => {
    setConfiguration(getConfig(appType, isMobile, isTablet, autoAddNewPostsCookie));
  }, [isMobile, autoAddNewPostsCookie]);

  useEffect(() => {
    setConfiguration(getConfig(appType, isMobile, isTablet, autoAddNewPostsCookie));
  }, []);

  useEffect(() => {
    /// google optimize is used only for application.
    if (ENV_GO_EXP_ID !== false && appType === 'app') {
      const intervalId = setInterval(() => {
        if (variant === undefined) {
          if (window.google_optimize !== undefined) {
            setVariant(window.google_optimize.get(ENV_GO_EXP_ID));
            clearInterval(intervalId);
          }
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    variant === '0' &&
      setConfiguration({
        ...currentConfiguration,
        allowExpandedPosts: true,
      });
    variant === '1' &&
      setConfiguration({
        ...currentConfiguration,
        allowExpandedPosts: false,
      });
  }, [variant]);

  return currentConfiguration;
}
