import React from 'react';
import ToolbarLogo from '../../Components/ToolbarLogo';
import ToolbarBackLink from '../../Components/ToolbarBackLink';
import ToolbarActions from '../../Components/ToolbarActions';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector, countOfPendingPostsSelector, applyPendings, hasHistorySelector } from '../../Redux/postsSlice';
import { isLoadingSelector as isSearchLoadingSelector } from '../../Redux/searchSlice';
import { toggleMobileMenu } from '../../Redux/appSlice';
import SpriteMap from '../../Components/SpriteMap';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { useLocation } from 'react-router';
import { matchUrl } from '../Routes';
import SWLink from '../../Components/SWLink';
import PendingPostsCTA from '../../Components/PendingPostsCTA';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import { useHistory } from 'react-router-dom';
import { withScrollingHelperContext } from '../../ContextProviders/ScrollingHelperContext';
import useFeedConfiguration from '../../Components/Hooks/useFeedConfiguration';

const Toolbar = ({ scrollToFirstArticle, messages, appType, setLastArticleSeen }) => {
  const feedConfiguration = useFeedConfiguration();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  /// logo is animated only while posts request is running
  const isLoading = useSelector(isLoadingSelector);
  const isSearchLoading = useSelector(isSearchLoadingSelector);
  const countOfPendingPosts = useSelector(countOfPendingPostsSelector);
  const hasHistory = useSelector(hasHistorySelector);

  const onHamburgerClicked = (event) => {
    dispatch(toggleMobileMenu());
    event.preventDefault();
    return false;
  };

  const applyPendingsCallback = () => {
    if (appType === 'app') {
      const routeMatch = matchUrl(location.pathname, appType);
      if (routeMatch.routeName === 'HOMEPAGE') {
        setLastArticleSeen(Date.now(), { SameSite: 'Lax', Secure: true });
      }
    }
    dispatch(applyPendings());
    scrollToFirstArticle();
  };

  return (
    <div className={'mnt-Toolbar'}>
      <div className={'mnt-ToolbarDesk'}>
        <div className={'mnt-LeftBar'}>
          <ToolbarLogo animateLogo={isLoading || isSearchLoading} />
        </div>
        <div className={'mnt-CenterBar'}>{appType === 'app' && hasHistory && <ToolbarBackLink />}</div>
        <div className={'mnt-RightBar'}>
          <ToolbarActions />
          <a href={messages.linkToDennikN} title={messages.dennikN} className={`mnt-logoN ${APP_LANG === 'cz' ? 'mnt-logoN-cz' : ''}`}>
            {messages.dennikN}
            <SpriteMap sprite={'logo_N'} />
          </a>
        </div>
      </div>
      <div className={'mnt-ToolbarMob'}>
        <div className={'mnt-LeftBar'}>
          {appType === 'app' && hasHistory ? (
            <button className={'mnt-backButton'} onClick={() => history.goBack()}>
              <SpriteMap sprite={'arrow_backward'} /> <span>{messages.backToNews}</span>
            </button>
          ) : (
            <SWLink to={ENV_APP_HOMEPAGE_ROUTE} className={'mnt-logoMnt'} title={messages.logoText}>
              <span className={'mnt-logoTitle'} dangerouslySetInnerHTML={{ __html: messages.logoTextHtml }} />
            </SWLink>
          )}
        </div>
        <div className={'mnt-CenterBar'}>
          <ToolbarLogo animateLogo={isLoading} />
        </div>
        <div className={'mnt-RightBar'}>
          <button className={'mnt-hamburgerMenu'} onClick={onHamburgerClicked}>
            <SpriteMap sprite={'hamburger'} />
          </button>
        </div>
      </div>
      {!feedConfiguration.autoAddNewPosts && countOfPendingPosts > 0 && countOfPendingPosts < 30 && <PendingPostsCTA onClick={applyPendingsCallback} countOfPendingPosts={countOfPendingPosts} />}
    </div>
  );
};

export default withScrollingHelperContext(withMessages(withAppContext(withUserSettingsContext(Toolbar))));
