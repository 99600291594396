/*******************************************************************************
 * Reader Slice
 * -----------------------------------------------------------------------------
 * ACTIONS
 *  fetchReader - current / newest posts from dennikn
 *  fetchReaderTrending - trending posts from dennikn
 *
 * SELECTORS:
 *  shouldUpdateCurrentPosts
 *  shouldUpdateTrendingPosts
 *
 *  isCurrentLoadingSelector
 *  isTrendingLoadingSelector
 *
 *  hasPostsSelector
 *  postsSelector
 ******************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { executeReaderCall, READER_POSTS_MINIMAL_CACHE_TIME_SECONDS } from '../api_definitions';
import { difference } from '../utils/merging';
import processPost from './mappers/readerMapper';

/// function that returns unique key for the post
/// used by .filter function
const postIdFn = (post) => post.id;

/// Actions --------------------------------------------------------------------

export const fetchReader = createAsyncThunk('setup/fetchReader', async (params = {}, { rejectWithValue }) => {
  try {
    return await executeReaderCall(params);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

/// it is calling same API point as fetchReader
/// it is separated only for cleaner implementation of extraReducers.
export const fetchReaderTrending = createAsyncThunk('setup/fetchReaderTrending', async (params = {}, { rejectWithValue }) => {
  try {
    const withTrending = { ...params, trending: 0 };
    return await executeReaderCall(withTrending);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const readerSlice = createSlice({
  name: 'reader',
  initialState: {
    current: {
      isLoading: false,
      error: null,
      requestId: null,
      timestamp: 0,
      posts: [],
    },
    trending: {
      isLoading: false,
      error: null,
      requestId: null,
      timestamp: 0,
      posts: [],
    },
  },
  reducers: {
    fulfilledFetchReader: (state, { payload }) => {
      state.current.posts = [...payload.posts.map(processPost('?ref=mwac')), ...difference(state.current.posts, payload.posts, postIdFn)];
    },
    fulfilledfetchReaderTrending: (state, { payload }) => {
      state.trending.posts = [...payload.posts.map(processPost('?ref=mwai')), ...difference(state.trending.posts, payload.posts, postIdFn)];
    },
  },
  extraReducers: {
    [fetchReader.fulfilled]: (state, action) => {
      readerSlice.caseReducers.fulfilledFetchReader(state, action);

      state.current.error = null;
      state.current.isLoading = false;
      state.current.timestamp = Math.floor(Date.now() / 1000);
    },
    [fetchReader.pending]: (state, { meta }) => {
      state.current.isLoading = true;
      state.current.requestId = meta;
      state.current.error = null;
    },
    [fetchReader.rejected]: (state, { meta, payload }) => {
      state.current.isLoading = false;
      state.current.requestId = meta;
      state.current.timestamp = Math.floor(Date.now() / 1000);
      state.current.error = payload;
    },

    [fetchReaderTrending.fulfilled]: (state, action) => {
      readerSlice.caseReducers.fulfilledfetchReaderTrending(state, action);

      state.trending.error = null;
      state.trending.isLoading = false;
      state.trending.timestamp = Math.floor(Date.now() / 1000);
    },
    [fetchReaderTrending.pending]: (state, { meta }) => {
      state.trending.isLoading = true;
      state.trending.requestId = meta;
      state.trending.error = null;
    },
    [fetchReaderTrending.rejected]: (state, { meta, payload }) => {
      state.trending.isLoading = false;
      state.trending.requestId = meta;
      state.trending.timestamp = Math.floor(Date.now() / 1000);
      state.trending.error = payload;
    },
  },
});

/// helper functions  ----------------------------------------------------------

const currentSelector = (state) => state.reader.current;
const trendingSelector = (state) => state.reader.trending;

/// Selectors ------------------------------------------------------------------

/**
 * desides if it should be called or not
 * results in `true` call the API, or `false` meaning that cached object is still valid
 *
 * valid time can be found in /api_definitions.js file
 */
export const shouldUpdateCurrentPostsSelector = createSelector(currentSelector, (state) => {
  /// make sure there are no two requests
  if (state.isLoading) return false;
  /// and enoght time has passed from last successful request
  return Math.floor(Date.now() / 1000) - state.timestamp > READER_POSTS_MINIMAL_CACHE_TIME_SECONDS;
});

export const shouldUpdateTrendingPostsSelector = createSelector(trendingSelector, (state) => {
  /// make sure there are no two requests
  if (state.isLoading) return false;
  /// and enoght time has passed from last successful request
  return Math.floor(Date.now() / 1000) - state.timestamp > READER_POSTS_MINIMAL_CACHE_TIME_SECONDS;
});

export const lastCurrentPostsTimestampSelector = createSelector(currentSelector, (state) => state.timestamp);
export const lastTrendingPostsTimestampSelector = createSelector(trendingSelector, (state) => state.timestamp);

/**
 * @returns Boolean
 */
export const isCurrentLoadingSelector = createSelector(currentSelector, (state) => state.isLoading);

/**
 * @returns Boolean
 */
export const isTrendingLoadingSelector = createSelector(trendingSelector, (state) => state.isLoading);

/**
 * @returns Boolean
 */
export const hasCurrentPostsSelector = createSelector(currentSelector, (state) => state.posts.length > 0);

/**
 * @returns Array<Post>
 */
export const currentPostsSelector = createSelector(currentSelector, (state) => state.posts);

/**
 * @returns Boolean
 */
export const hasTrendingPostsSelector = createSelector(trendingSelector, (state) => state.posts.length > 0);

/**
 * @returns Array<Post>
 */
export const trendingPostsSelector = createSelector(trendingSelector, (state) => state.posts);

export const { fulfilledFetchReader, fulfilledfetchReaderTrending } = readerSlice.actions;
export default readerSlice.reducer;
