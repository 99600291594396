import React from 'react';
import { Youtube } from 'reactjs-social-embed';

const ArticleEmbed = ({ hostName, id }) => {
  if (hostName === 'youtube') {
    return <Youtube width="100%" parentClass="video-responsive" iframeClass="v-container" id={id} />;
  }
  //  We are interested only on youtube embeds

  // else if (hostName === 'twitter') {
  //   return <TwitterEmbed id={id} align={'center'} darkTheme={isDarkMode} parentClass={'mnt-twitter-post'} />;
  // } else if (hostName === 'facebook-video') {
  //   <FacebookEmbed width="100%" postUrl={url} parentClass={'mnt-fb-post'} />;
  // } else if (hostName === 'facebook') {
  //   return <FacebookEmbed width="500" postUrl={url} parentClass={'mnt-fb-post'} />;
  // }
  return <></>;
};

export default ArticleEmbed;
