import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Ticker from '../../Components/Controllers/Ticker';
import TrendingPost from '../../Components/TrendingPost';
import {
  /// readerSlice
  fetchReaderTrending,
  hasTrendingPostsSelector,
  isTrendingLoadingSelector,
  lastTrendingPostsTimestampSelector,
  trendingPostsSelector,
} from '../../Redux/readerSlice';
import TrendingPostsSkeleton from '../../Components/Skeletons/TrendingPostsSkeleton';

const TrendingPosts = () => {
  const dispatch = useDispatch();
  const lastTimestamp = useSelector(lastTrendingPostsTimestampSelector);
  const hasPosts = useSelector(hasTrendingPostsSelector);
  const isLoading = useSelector(isTrendingLoadingSelector);
  const posts = useSelector(trendingPostsSelector);

  /**
   * method to be used by <Ticker component
   */
  const onTick = () => {
    dispatch(fetchReaderTrending());
  };

  return (
    <div className={'mnt-CurrentPosts'}>
      <Ticker
        //
        lastTimestamp={lastTimestamp}
        timeoutAfterSeconds={ENV_READER_TICKER_SPEED}
        onTick={onTick}
        skipFirst={false}
        tickerSpeedSeconds={ENV_READER_TICKER_SPEED}
        fireOnChange={[]}
      />

      {!hasPosts && isLoading && <TrendingPostsSkeleton repeat={10} />}

      {posts.map((post) => (
        <TrendingPost post={post} key={post.id.toString()} />
      ))}
    </div>
  );
};

export default TrendingPosts;
