export default (postObject) => {
  return {
    excerpt: postObject.excerpt,
    id: postObject.id,
    slug: postObject.slug,
    url: postObject.url,
    name: postObject.name,
    image: postObject.image,
    tags: postObject.tags,
    authors: postObject.authors,
    firstAuthor: postObject.authors && postObject.authors.length > 0 ? postObject.authors[0] : null,
    isActive: postObject.settings.indexOf('ended') === -1,
    isNotSport: postObject.settings.indexOf('sport') === -1,
    isShown: postObject.settings.indexOf('hidden') === -1,
    updated_at: postObject.updated_at,
    last_published_at: postObject.last_published_at,
  };
};
