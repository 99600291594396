import React, { memo } from 'react';

const ShareToFacebook = ({ url, afterClick, children }) => {
  const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url + '?ref=pop';

  const onClick = () => {
    window.open(facebookUrl, 'Facebook', `width=480, height=480, top=${window.innerHeight / 2 - 240}, left=${window.innerWidth / 2 - 240}`);
    afterClick && afterClick();
  };

  return (
    <button href={facebookUrl} onClick={onClick}>
      {children}
    </button>
  );
};

export default memo(ShareToFacebook);
