/*******************************************************************************
 * Posts Slice
 * -----------------------------------------------------------------------------
 * ACTIONS
 *  tickPosts - use it for first call and also for ticker
 *  fetchPosts - additional posts, lazyload and stuff
 *
 * SELECTORS:
 ******************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { difference } from '../utils/merging';
import processPost from './mappers/postMapper';
import processMeta from './mappers/metaMapper';

/// function that returns unique key for the post
/// used by .filter function
const postIdFn = (post) => post.id;

/// Actions --------------------------------------------------------------------

/**
 * TickPosts action should fetch newest posts for active page
 * parameters are mapped from the current router Match object
 * data should not be merged with state rightaway,
 * but scheduled for further updates
 */
export const tickPosts = createAsyncThunk('posts/tickPosts', async ({ routeMatch, routeParams, exclude_category }, { rejectWithValue }) => {
  const options = routeParams;
  if (exclude_category) options.exclude_category = exclude_category;
  else delete options.exclude_category;
  const params = routeMatch.prepareParams(options);
  try {
    const response = await routeMatch.tick(params);
    if (response.hasOwnProperty('status') && response.status === 'error') {
      throw new Error(response.code);
    }
    return response;
  } catch (ex) {
    return rejectWithValue(ex.message);
  }
});

/**
 * FetchPosts action can be more customized than tickPosts
 * it will be used mainly for lazyload
 */
export const fetchPosts = createAsyncThunk('posts/fetchPosts-0', async ({ routeMatch, routeParams, beforeId, exclude_category }, { rejectWithValue }) => {
  const options = routeParams;
  if (exclude_category) options.exclude_category = exclude_category;
  else delete options.exclude_category;
  const params = routeMatch.prepareParams(options);
  try {
    return await routeMatch.lazyLoad(params, beforeId);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

/**
 * FetchPosts action can be more customized than tickPosts
 * it will be used mainly for lazyload
 */
export const fetchOriginalPosts = createAsyncThunk('posts/fetchOriginalPosts', async ({ routeMatch, routeParams, exclude_category }, { rejectWithValue }) => {
  const options = routeParams;
  if (exclude_category) options.exclude_category = exclude_category;
  else delete options.exclude_category;
  const params = routeMatch.prepareParams(options);
  try {
    const response = await routeMatch.tick(params);
    if (response.hasOwnProperty('status') && response.status === 'error') {
      throw new Error(response.code);
    }
    return response;
  } catch (ex) {
    return rejectWithValue(ex.message);
  }
});

export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    url: '/',
    pageKey: 'firstpage',
    firstPageKey: false,
    isFirstPage: true,
    meta: {},
    fetchTimestamp: 0,
    timestamp: 0,
    newPosts: [],
    originalPosts: [],
    olderPosts: [],
    pending: {
      meta: {},
      posts: [],
    },
    historyData: {},
    scrollPosition: 0,
    hasMorePosts: true,
    isLoading: false,
    request: null,
    error: null,
    empty: false,
  },
  reducers: {
    /// usecase: when user clicks on "there are new posts"
    /// merge all posts that are waiting in pendings property
    /// pendings are prepended to the existing posts
    /// pending should be set to empty array
    applyPendings: (state) => {
      const seenPosts = [...state.newPosts, ...state.originalPosts]
        .map((post) => ({ ...post, forceNotSeen: false }))
        .filter((post) => post.seen)
        .map((post) => post.id);
      try {
        /// run until we hit first same id:
        const firstId = [...state.newPosts, ...state.originalPosts][0].id;
        for (let i = 0; i < state.pending.posts.length; i++) {
          if (state.pending.posts[i].id === firstId) break;
          state.pending.posts[i].forceNotSeen = true;
        }
      } catch (ex) {}
      state.newPosts = [...difference(state.pending.posts, [...state.newPosts, ...state.originalPosts], postIdFn), ...state.newPosts];
      /// preserve seen flag
      state.newPosts = state.newPosts.map((post) => (seenPosts.indexOf(post.id) > -1 ? { ...post, seen: true } : post));
      state.meta = processMeta(state.pending.meta);
      state.pending = { meta: {}, posts: [] };
    },

    /// usecase: newly openned page
    /// setup new posts is initialization reducer
    /// simply override everything in store
    setNewPostsResult: (state, { payload }) => {
      state.fetchTimestamp = payload.timestamp;
      state.meta = processMeta(payload.meta);
      state.timestamp = Math.floor(Date.now() / 1000);
      state.originalPosts = [...payload.posts.map((post) => processPost(post, state.meta))];
      state.hasMorePosts = payload.posts.length === payload.meta.count;
      state.pending = { meta: {}, posts: [] };
    },

    /// this is new "ticker"
    /// if there are new posts available, schedule update with `pending` array
    /// NOTE: there can already be scheduled posts just waiting up there, dont forget about them!
    preparePengings: (state, { payload }) => {
      state.pending.posts = [...payload.posts.map((post) => processPost(post, state.meta)), ...difference(state.pending.posts, payload.posts, postIdFn)];
      state.pending.meta = { ...payload.meta };
    },

    /// usecase: lazyload
    /// next page doesn't need to wait for changes
    /// differrence is not even necessary, but....just to make sure
    nextPagePostResults: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      /// merging -----------------------------------
      state.olderPosts = [...difference(state.olderPosts, payload.posts, postIdFn), ...payload.posts.map((post) => processPost(post, state.meta))];
    },

    invalidateData: (state) => {
      state.meta = {};
      state.newPosts = [];
      state.originalPosts = [];
      state.olderPosts = [];
    },

    invalidateOldAndNew: (state) => {
      state.newPosts = [];
      state.olderPosts = [];
    },

    setPageKey: (state, { payload }) => {
      state.pageKey = payload;
      if (state.firstPageKey === false) state.firstPageKey = payload;
    },

    /// location changed:
    onLocationChanged: (state, { payload }) => {
      state.historyData[state.pageKey] = {
        newPosts: state.newPosts,
        olderPosts: state.olderPosts,
        originalPosts: state.originalPosts,
        timestamp: state.timestamp,
        meta: state.meta,
        pending: state.pending,
        hasMorePosts: state.hasMorePosts,
        scrollPosition: payload.scrollPosition,
        isFirstPage: state.isFirstPage,
      };

      state.pageKey = payload.pageKey;
      if (Object.keys(state.historyData).length > 1) {
        state.newPosts = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].newPosts : [];
        state.olderPosts = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].olderPosts : [];
        state.originalPosts = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].originalPosts : [];
        state.timestamp = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].timestamp : 0;
        state.meta = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].meta : {};
        state.pending = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].pending : { meta: {}, posts: [] };
        state.hasMorePosts = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].hasMorePosts : true;
        state.scrollPosition = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].scrollPosition : 0;
        state.isFirstPage = state.historyData.hasOwnProperty(payload.pageKey) ? state.historyData[payload.pageKey].isFirstPage : false;
      }
    },

    markPostAsRead: (state, { payload }) => {
      state.originalPosts = state.originalPosts.map((post) => (post.id === payload.postId ? { ...post, seen: true, forceNotSeen: false } : post));
      state.olderPosts = state.olderPosts.map((post) => (post.id === payload.postId ? { ...post, seen: true, forceNotSeen: false } : post));
      state.newPosts = state.newPosts.map((post) => (post.id === payload.postId ? { ...post, seen: true, forceNotSeen: false } : post));
    },
  },

  extraReducers: {
    [fetchPosts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPosts.rejected]: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [fetchPosts.fulfilled]: (state, { payload }) => {
      postsSlice.caseReducers.nextPagePostResults(state, { payload: payload });

      state.empty = payload.posts.length === 0;
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },

    [fetchOriginalPosts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchOriginalPosts.rejected]: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [fetchOriginalPosts.fulfilled]: (state, { payload }) => {
      postsSlice.caseReducers.setNewPostsResult(state, { payload: payload });
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },

    [tickPosts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [tickPosts.rejected]: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [tickPosts.fulfilled]: (state, { payload }) => {
      if (state.meta.url && state.meta.url === payload.meta.url) {
        postsSlice.caseReducers.preparePengings(state, { payload: payload });
      } else {
        if (payload.timestamp > state.fetchTimestamp) {
          postsSlice.caseReducers.setNewPostsResult(state, { payload: payload });
        }
      }

      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },
  },
});

///
/// helper functions  ----------------------------------------------------------
///

const postsStateSelector = (state) => state.posts;
const findFirstByType = (type) => (list) => {
  const res = list.filter((it) => it.type === type);
  return res.length > 0 ? res[0] : null;
};

///
/// Selectors ------------------------------------------------------------------
///

/**
 * @returns Boolean
 */
export const isLoadingSelector = createSelector(postsStateSelector, (state) => state.isLoading);

/**
 * Are there new posts pending to be injected to the feed?
 * @returns Boolean
 */
export const countOfPendingPostsSelector = createSelector(postsStateSelector, (state) => {
  try {
    if (state.pending.posts.length > 0 && state.pending.posts[0].id !== [...state.newPosts, ...state.originalPosts][0].id) {
      const pendingIds = state.pending.posts.map((post) => post.id);
      const currentIds = [...state.newPosts, ...state.originalPosts].map((post) => post.id);
      return pendingIds.filter((pendingId) => currentIds.indexOf(pendingId) === -1).length;
    }
  } catch (ex) {}
  return 0;
});

/**
 * Test for sticky content
 * @returns Boolean
 */
export const hasStickySelector = createSelector(postsStateSelector, (state) => state.meta.excerpt);

/**
 * returns sticky body
 */
export const stickyContentSelector = createSelector(postsStateSelector, (state) => state.meta);

export const hasPostsSelector = createSelector(postsStateSelector, (state) => !!(state.originalPosts.length > 0 || state.olderPosts.length > 0 || state.newPosts.length > 0));

export const originalPostsSelector = createSelector(postsStateSelector, (state) => state.originalPosts);
export const newPostsSelector = createSelector(postsStateSelector, (state) => state.newPosts);
export const olderPostsSelector = createSelector(postsStateSelector, (state) => state.olderPosts);
export const firstPostSelector = createSelector(postsStateSelector, (state) => (state.newPosts.length > 0 ? state.newPosts[0] : state.originalPosts.length > 0 ? state.originalPosts[0] : null));
export const hasMorePostsSelector = createSelector(postsStateSelector, (state) => state.hasMorePosts);

export const hasErrorSelector = createSelector(postsStateSelector, (state) => state.error !== null);
export const isEmptySelector = createSelector(postsStateSelector, (state) => state.empty);
export const errorMessageSelector = createSelector(postsStateSelector, (state) => state.error);
export const lastTimestampSelector = createSelector(postsStateSelector, (state) => state.timestamp);

export const scrollPositionSelector = createSelector(postsStateSelector, (state) => state.scrollPosition);
export const hasHistorySelector = createSelector(postsStateSelector, (state) => !(state.firstPageKey === false || state.firstPageKey === state.pageKey));

export const { applyPendings, onLocationChanged, markPostAsRead, invalidateData, invalidateOldAndNew, setPageKey } = postsSlice.actions;

export default postsSlice.reducer;
