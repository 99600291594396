import React from 'react';
import ArticleDateTime from '../ArticleDateTime';
import AdminEditLink from '../AdminEditLink';
import ShareLinksMenu from '../ShareLinksMenu';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const FeedLiveArticleToolbar = ({ article, showShareMenu, toggleShareMenuFn, messages }) => {
  return (
    <div className={'mnt-liveShare-wrapper'}>
      <div className={'mnt-live-wrapper'}>
        {article.isActive && <span className={'mnt-live'}>{messages.liveBadge}</span>}
        {article.last_published_at && <ArticleDateTime className={article.isActive ? 'mnt-time-spaced' : ''} dateString={article.last_published_at} />}
      </div>
      <div className={'mnt-share-wrapper'}>
        <AdminEditLink url={ENV_EDIT_LIVE_URL} id={article.id} />
      </div>
      {showShareMenu && <ShareLinksMenu toggleShareMenuFn={toggleShareMenuFn} articleObject={article} />}
    </div>
  );
};

export default withMessages(FeedLiveArticleToolbar);
