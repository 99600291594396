import React, { useContext, useEffect } from 'react';

const ScrollingHelperContext = React.createContext({});
const useScrollingHelperContext = () => useContext(ScrollingHelperContext);

const ScrollingHelperContextProvider = ({ appType, children }) => {
  const mobileTopDelta = 55;
  const desktopTopDelta = 30;

  const getScrollTop = () => {
    if (appType === 'app' || appType === 'feed') return window.scrollY;
    return document.querySelector('.mnt-js-scrolling-element').scrollTop;
  };

  const getTopDelta = (direction) => {
    if (window.innerWidth <= 768) return direction == 'next' ? mobileTopDelta : 0;
    return desktopTopDelta;
  };

  const setScrollTop = (top, smooth) => {
    const options = {
      top: top,
      left: 0,
    };
    if (smooth === true) options.behavior = 'smooth';

    if (appType === 'app' || appType === 'feed') {
      window.scrollTo(options);
    }
    document.querySelector('.mnt-js-scrolling-element').scrollTo(options);
  };

  const scrollToTop = () => {
    setScrollTop(0, true);
  };

  const scrollToNext = () => {
    const scrollTop = getScrollTop();
    const feedArticlesDOMNodes = [...document.querySelectorAll('.js-hook-feed-article')];
    const topDelta = getTopDelta('next');

    for (let i = 0; i < feedArticlesDOMNodes.length; i++) {
      if (feedArticlesDOMNodes[i].offsetTop > scrollTop) {
        setScrollTop(feedArticlesDOMNodes[i].offsetTop + topDelta, true);
        break;
      }
    }
  };

  const scrollToPrev = () => {
    const scrollTop = getScrollTop();
    const topDelta = getTopDelta('prev');
    const feedArticlesDOMNodes = [...document.querySelectorAll('.js-hook-feed-article')];
    for (let i = feedArticlesDOMNodes.length - 1; i >= 0; i--) {
      if (feedArticlesDOMNodes[i].offsetTop + topDelta < scrollTop) {
        setScrollTop(feedArticlesDOMNodes[i].offsetTop + topDelta, true);
        break;
      }
    }
  };

  const scrollToFirstArticle = () => {
    const topDelta = getTopDelta('next');
    const feedArticlesDOMNodes = [...document.querySelectorAll('.js-hook-feed-article')];

    setScrollTop(feedArticlesDOMNodes[0].offsetTop + topDelta, true);
  };

  const onKeyDownListener = (event) => {
    if (document.activeElement.tagName !== 'BODY') return;
    if (event.keyCode == '38') {
      event.preventDefault();
      scrollToPrev();
    } else if (event.keyCode == '40') {
      event.preventDefault();
      scrollToNext();
    }
  };

  useEffect(() => {
    (appType === 'app' || appType === 'feed') && document.body.addEventListener('keydown', onKeyDownListener);
    return () => document.body.removeEventListener('keydown', onKeyDownListener);
  }, []);

  return (
    <ScrollingHelperContext.Provider
      value={{
        scrollToTop: scrollToTop,
        scrollToNext: scrollToNext,
        scrollToPrev: scrollToPrev,
        scrollToFirstArticle: scrollToFirstArticle,
      }}
    >
      {children}
    </ScrollingHelperContext.Provider>
  );
};

const withScrollingHelperContext = (Component) => (props) => {
  const value = useScrollingHelperContext();

  return (
    <Component
      {...props}
      /// scrolling functions
      scrollToTop={value.scrollToTop}
      scrollToNext={value.scrollToNext}
      scrollToPrev={value.scrollToPrev}
      scrollToFirstArticle={value.scrollToFirstArticle}
    />
  );
};

export function useScrollToTop() {
  const ctx = useScrollingHelperContext();
  return ctx.scrollToTop;
}

export function useScrollToFirstArticle() {
  const ctx = useScrollingHelperContext();
  return ctx.scrollToFirstArticle;
}

export default ScrollingHelperContextProvider;
export { useScrollingHelperContext, withScrollingHelperContext };
