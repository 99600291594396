import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const FeedArticleSkeleton = ({ repeat, isDarkMode, appType }) => {
  const color = isDarkMode && appType !== 'widget' ? '#222' : '#eee';
  const highlightColor = isDarkMode && appType !== 'widget' ? '#444' : '#f5f5f5';

  const article = (id) => (
    <SkeletonTheme color={color} highlightColor={highlightColor} key={id}>
      <div className={'mnt-FeedArticle js-hook-feed-article'}>
        <div className={'mnt-toolbar'}>
          <div style={{ minWidth: '100px', paddingTop: '18px' }}>
            <Skeleton />
          </div>
          <div style={{ minWidth: '20px', paddingTop: '18px' }}>
            <Skeleton />
          </div>
        </div>

        <div className={`mnt-article`} style={{ lineHeight: 1.2 }}>
          <Skeleton count={3} />
          <Skeleton width={'75%'} />

          <div className={'mnt-tags mnt-fade-end'} style={{ minWidth: '20px', paddingTop: '18px' }}>
            {new Array(3).fill(0).map((_, i) => (
              <Skeleton key={i} width={'120px'} height={'30px'} />
            ))}
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );

  return new Array(repeat).fill(0).map((_, i) => article(i));
};

export default withAppContext(withUserSettingsContext(FeedArticleSkeleton));
