import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import SWLink from './SWLink';

const TagLink = ({ id, slug, settings, children, additionalProps }) => {
  const url = generatePath(ENV_APP_TAG_ROUTE, { id: id, tag: slug });
  const isHighlighted = settings && settings.indexOf('highlight') > -1;

  return (
    <SWLink to={url} className={isHighlighted ? 'mnt-tag mnt-highlight' : 'mnt-tag'} {...additionalProps}>
      {children}
    </SWLink>
  );
};

TagLink.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  settings: PropTypes.array,
  additionalProps: PropTypes.object,
};

export default memo(TagLink);
