import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Ticker from '../../Components/Controllers/Ticker';
import CurrentPost from '../../Components/CurrentPost';
import CurrentPostsSkeleton from '../../Components/Skeletons/CurrentPostsSkeleton';
import {
  /// readerSlice
  currentPostsSelector,
  fetchReader,
  hasCurrentPostsSelector,
  isCurrentLoadingSelector,
  lastCurrentPostsTimestampSelector,
} from '../../Redux/readerSlice';

const CurrentPosts = () => {
  const dispatch = useDispatch();
  const lastTimestamp = useSelector(lastCurrentPostsTimestampSelector);
  const hasPosts = useSelector(hasCurrentPostsSelector);
  const isLoading = useSelector(isCurrentLoadingSelector);
  const posts = useSelector(currentPostsSelector);

  /**
   * method to be used by <Ticker component
   */
  const onTick = () => {
    dispatch(fetchReader());
  };

  return (
    <div className={'mnt-CurrentPosts'}>
      <Ticker
        //
        lastTimestamp={lastTimestamp}
        timeoutAfterSeconds={ENV_READER_TICKER_SPEED}
        onTick={onTick}
        skipFirst={false}
        tickerSpeedSeconds={ENV_READER_TICKER_SPEED}
        fireOnChange={[]}
      />

      {!hasPosts && isLoading && <CurrentPostsSkeleton repeat={10} />}

      {posts.map((post) => (
        <CurrentPost post={post} key={post.id.toString()} />
      ))}
    </div>
  );
};

export default CurrentPosts;
