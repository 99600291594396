import React, { useCallback, useEffect, useState } from 'react';
import { TickController } from '../../utils/tick_controller';
import PropTypes from 'prop-types';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const Ticker = ({ tickerSpeedSeconds, onTick, skipFirst, lastTimestamp, timeoutAfterSeconds, fireOnChange, onlyOnce, appIsActiveState }) => {
  const [wasOnceTriggered, setWasOnceTriggered] = useState(false);
  const [hasSsr, setHasSsr] = useState(false);
  const [pastFireOnChange, setPastFireOnChange] = useState(onlyOnce ? fireOnChange[0] : undefined);
  const [lastFireOnChange, setLastFireOnChange] = useState(onlyOnce ? fireOnChange[0] : undefined);

  useEffect(() => {
    if (onlyOnce && fireOnChange.length === 1 && fireOnChange[0] === '') {
      return;
    }
    setWasOnceTriggered(true);
    setHasSsr(window.hasOwnProperty('__INITIAL_STATE__'));
  }, []);

  useEffect(() => {
    if (onlyOnce) {
      setWasOnceTriggered(!(fireOnChange[0] === ''));
      setLastFireOnChange(fireOnChange[0]);
      setPastFireOnChange((previousState) => {
        if (previousState !== lastFireOnChange && lastFireOnChange !== '') {
          return lastFireOnChange;
        }
      });
      lastFireOnChange !== fireOnChange[0] && lastFireOnChange !== '' && setWasOnceTriggered(false);
    }
  }, [...fireOnChange]);

  let onTickLocal = useCallback(() => {
    if (onlyOnce && (wasOnceTriggered || pastFireOnChange === fireOnChange[0])) {
      return;
    }
    if (Date.now() / 1000 - lastTimestamp > timeoutAfterSeconds) {
      (!hasSsr || wasOnceTriggered) && appIsActiveState && onTick();
      (!hasSsr || wasOnceTriggered) && appIsActiveState && setWasOnceTriggered(true);
    }
  }, [hasSsr, wasOnceTriggered, pastFireOnChange, appIsActiveState, ...fireOnChange]);

  let tickController;

  useEffect(() => {
    tickController && tickController.stopTicker();
    tickController = new TickController(tickerSpeedSeconds, onTickLocal);

    if (skipFirst) tickController.scheduleTicker();
    else {
      !onlyOnce && tickController.fireTicker();
      onlyOnce && tickController.fireTicker();
      onlyOnce && tickController.stopTicker();
    }

    return () => tickController.stopTicker();
  }, [appIsActiveState, ...fireOnChange]);

  useEffect(() => {
    tickController && tickController.stopTicker();
    tickController = new TickController(tickerSpeedSeconds, onTickLocal);

    tickController.scheduleTicker();

    return () => tickController.stopTicker();
  }, [onTickLocal]);

  useEffect(() => {
    if (onlyOnce) {
      tickController && tickController.stopTicker();
      tickController = new TickController(tickerSpeedSeconds, onTickLocal);

      tickController.fireTicker();

      return () => tickController.stopTicker();
    }
  }, [wasOnceTriggered]);

  return <></>;
};

/// Prop types  ----------------------------------------------------------------

Ticker.propTypes = {
  /// ticker timeout
  tickerSpeedSeconds: PropTypes.number.isRequired,
  /// function to be executed on each tick
  onTick: PropTypes.func.isRequired,
  /// skip first
  /// in case of serverside rendering, this should be false
  /// othervise it is true
  skipFirst: PropTypes.bool.isRequired,
  /// this one can stop onTick function from beeing called
  lastTimestamp: PropTypes.number.isRequired,
  timeoutAfterSeconds: PropTypes.number.isRequired,
  /// to keep consistency, use this ticker to fetch data
  /// if data doesn't need ticker, set onlyOnce to true
  onlyOnce: PropTypes.bool,
};

export default withAppContext(Ticker);
