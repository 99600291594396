import React from 'react';
import PropTypes from 'prop-types';

const Toast = ({ onClick, children }) => {
  return <span className={'mnt-Toast'}>{children}</span>;
};

Toast.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default Toast;
