import React from 'react';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';
import { executeLiveArchiveCall, executePostsCall, executeSearchCall } from '../api_definitions';
import Detail from './Sections/Detail';
import Feed from './Sections/Feed';
import Search from './Sections/Search';
import LiveArchive from './Sections/LiveArchive';
import FeedRedirectWrapper from './FeedRedirectWrapper';

/*******************************************************************************
 * Exports:
 *  list of routes as routesArray to be used with router
 *    each route contains:
 *      - path
 *      - component that will be used in React
 *      - exact flag
 *      - prepareParams - function - place to make changes to parameters from matching route
 *      - tick - async function - used for initial load, and for timed/repeated updates
 *      - lazyLoad - async function - used for...well...lazy loading
 *      - isLoading - function - should return state variable that shows us loading state (used from SSR)
 *
 * IMPORTANT: ------------------------------------------------------------------
 *  Routes are filtered at the end of the routesArray array
 *  currently it is filtered by ENV_SHOW_LIVES config key
 *  ----------------------------------------------------------------------------
 *
 *  matchUrl function, usefull on server side rendering
 *  [default] Routes react component
 ******************************************************************************/

export const routesArray = [
  {
    /// -------------------------------------- Homepage
    routeName: 'HOMEPAGE',
    path: ENV_APP_HOMEPAGE_ROUTE,
    component: <FeedRedirectWrapper />,
    exact: true,
    prepareParams: (params) => params,
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Tag
    routeName: 'TAG',
    path: ENV_APP_TAG_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.tag = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Category
    routeName: 'CATEGORY',
    path: ENV_APP_CATEGORY_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.category = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Important
    routeName: 'IMPORTANT',
    path: ENV_APP_IMPORTANT_ROUTE,
    component: <Feed />,
    prepareParams: (params) => ({ ...params, important: 1 }),
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Live
    routeName: 'LIVE_ARCHIVE',
    path: ENV_APP_LIVE_ARCHIVE_ROUTE,
    component: <LiveArchive />,
    prepareParams: (params) => params,
    tick: async () => {
      return await executeLiveArchiveCall({});
    },
    lazyLoad: async (params, pageNumber) => {
      return await executeLiveArchiveCall({ page: pageNumber });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Live detail
    routeName: 'LIVE',
    path: ENV_APP_LIVE_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.live = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Search
    routeName: 'SEARCH',
    path: ENV_APP_SEARCH_ROUTE,
    component: <Search />,
    prepareParams: (params) => ({ ...params, type: 'all' }),
    tick: async (params) => {
      return await executeSearchCall({ ...params });
    },
    lazyLoad: async (params, beforeId, allResults) => {
      return await executeSearchCall({ ...params, before: beforeId, full: allResults });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Detail
    routeName: 'DETAIL',
    path: ENV_APP_DETAIL_ROUTE,
    component: <Detail />,
    prepareParams: (params) => params,
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async () => {
      return null;
    },
    isLoading: (state) => state.posts.isLoading,
  },
].filter((it) => {
  /// Disable Routes based on current config
  /// Dont use LIVE_ARCHIVE and LIVES route if ENV_SHOW_LIVES is set to FALSE
  if (!ENV_SHOW_LIVES) return ['LIVE_ARCHIVE', 'LIVES'].indexOf(it.routeName) === -1;
  return true;
});

export const routesArrayFeed = [
  {
    /// -------------------------------------- Homepage
    routeName: 'HOMEPAGE',
    path: ENV_FEED_HOMEPAGE_ROUTE,
    component: <FeedRedirectWrapper />,
    exact: true,
    prepareParams: (params) => params,
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Tag
    routeName: 'TAG',
    path: ENV_FEED_TAG_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.tag = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Category
    routeName: 'CATEGORY',
    path: ENV_FEED_CATEGORY_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.category = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Important
    routeName: 'IMPORTANT',
    path: ENV_FEED_IMPORTANT_ROUTE,
    component: <Feed />,
    prepareParams: (params) => ({ ...params, important: 1 }),
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Live
    routeName: 'LIVE_ARCHIVE',
    path: ENV_FEED_LIVE_ARCHIVE_ROUTE,
    component: <LiveArchive />,
    prepareParams: (params) => params,
    tick: async () => {
      return await executeLiveArchiveCall({});
    },
    lazyLoad: async (params, pageNumber) => {
      return await executeLiveArchiveCall({ page: pageNumber });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Live detail
    routeName: 'LIVE',
    path: ENV_FEED_LIVE_ROUTE,
    component: <Feed />,
    prepareParams: (params) => {
      if (params.hasOwnProperty('id')) {
        params.live = params.id;
        delete params.id;
      }
      return params;
    },
    tick: async (params) => {
      return await executePostsCall(params);
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Search
    routeName: 'SEARCH',
    path: ENV_FEED_SEARCH_ROUTE,
    component: <Search />,
    prepareParams: (params) => ({ ...params, type: 'all' }),
    tick: async (params) => {
      return await executeSearchCall({ ...params });
    },
    lazyLoad: async (params, beforeId) => {
      return await executePostsCall({ ...params, before: beforeId });
    },
    isLoading: (state) => state.posts.isLoading,
  },
  {
    /// -------------------------------------- Detail
    routeName: 'DETAIL',
    path: ENV_FEED_DETAIL_ROUTE,
    component: <Detail />,
    prepareParams: (params) => params,
    tick: async (params) => {
      return await executePostsCall({ ...params });
    },
    lazyLoad: async () => {
      return null;
    },
    isLoading: (state) => state.posts.isLoading,
  },
].filter((it) => {
  /// Disable Routes based on current config
  /// Dont use LIVE_ARCHIVE and LIVES route if ENV_SHOW_LIVES is set to FALSE
  if (!ENV_SHOW_LIVES) return ['LIVE_ARCHIVE', 'LIVES'].indexOf(it.routeName) === -1;
  return true;
});

/**
 * Returns matched route or null from routesArray list
 * useful for server side rendering
 *
 * @param {*} url
 * @param {*} appType
 */
export const matchUrl = (url, appType) => {
  let mappedRoutesArray = routesArray;
  if (appType === 'feed') {
    mappedRoutesArray = routesArrayFeed;
  }
  let matched = mappedRoutesArray[0];

  for (let i = 0; i < mappedRoutesArray.length; i++) {
    const matchedPath = matchPath(url, mappedRoutesArray[i]);
    if (matchedPath !== null) {
      matched = {
        ...mappedRoutesArray[i],
        params: matchedPath.params,
      };
      break;
    }
  }

  return matched;
};

const Routes = ({ appType }) => {
  if (appType !== 'feed') {
    return (
      <Switch>
        {routesArray.map((it) => (
          <Route key={it.path} path={it.path} exact={it.exact ? it.exact : false}>
            {it.component}
          </Route>
        ))}
        <Redirect
          to={{
            pathname: ENV_APP_HOMEPAGE_ROUTE,
            state: {
              fromNonExistingPage: true,
            },
          }}
        />
      </Switch>
    );
  }
  return (
    <Switch>
      {routesArrayFeed.map((it) => (
        <Route key={it.path} path={it.path} exact={it.exact ? it.exact : false}>
          {it.component}
        </Route>
      ))}
      <Redirect
        to={{
          pathname: ENV_FEED_HOMEPAGE_ROUTE,
          state: {
            fromNonExistingPage: true,
          },
        }}
      />
    </Switch>
  );
};

export default Routes;
