import React from 'react';

const SpriteMap = ({ sprite, classname }) => {
  return (
    <svg className={classname}>
      {WEBPACK_ASSETS_FOLDER_OVERRIDE ? (
        /// assets folder can be overriden for staging: 'risingn.sk'
        <use xlinkHref={'#sprite-' + sprite} />
      ) : (
        <use xlinkHref={'#sprite-' + sprite} />
      )}
    </svg>
  );
};

export default SpriteMap;
