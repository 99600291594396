import { useEffect } from 'react';
import { useRempContext } from '../../ContextProviders/RempContextProvider';
import { useGTMTrackerContext } from '../../ContextProviders/GTMTrackerContextProvider';

export default function useTrackPageEvents(feedMeta) {
  const rempContext = useRempContext();
  const gtmContext = useGTMTrackerContext();

  useEffect(() => {
    if (!feedMeta || !feedMeta.type) return;

    if (feedMeta.type === 'live') {
      let author = '';
      if (feedMeta.authors.length > 0) {
        author = feedMeta.authors[0].name;
      }
      const tags = feedMeta.tags.map((tag) => tag.slug);
      let category = null;
      if (feedMeta.tags.length > 0) {
        category = feedMeta.tags[0].parent_category;
      }

      rempContext.trackLive(feedMeta.id, author, tags, category);
    } else {
      rempContext.stopTracking();
    }
    if (feedMeta.type === 'category') gtmContext.trackCategory(feedMeta.slug);
    if (feedMeta.type === 'tag') gtmContext.trackTag(feedMeta.slug);
    if (feedMeta.type === 'important') gtmContext.trackImportant();
  }, [feedMeta]);
}
