module.exports = {
  /// urls  ----------------------------------------
  url: {
    important: 'dolezite',
    theme: 'tema',
    category: 'rubrika',
    live: 'live',
    liveStream: 'live-prenosy',
    search: 'hladaj',
    detail: 'minuta',
  },
  /// settings  ----------------------------------------
  shortCode: 'sk',
  longCode: 'sk_SK',

  appName: 'Minúta po minúte',
  appShortName: 'MPM',
  appDescription: 'Rýchle správy Denníka N',

  /// --------------------------------------------------
  newPostsPending: 'Nove minutky',
  newPostsPendingFn: (countOfNewPosts) => {
    if (countOfNewPosts === 1) return '1 Nová správa';
    if (countOfNewPosts <= 4) return `${countOfNewPosts} Nové správy`;
    return `${countOfNewPosts} Nových správ`;
  },

  /// Menu section  ------------------------------------
  menuLive: 'Online prenosy',
  activeOnlineLiveNews: 'AKTÍVNE',
  currentTags: 'AKTUÁLNE TÉMY',
  settings: 'Nastavenie',
  categories: 'RUBRIKY',
  subscribeToNewsletter: 'Newsletter mnt.sk',
  showSport: 'Šport',
  showSportLong: 'Zobraziť šport',
  darkMode: 'Tmavý režim',
  search: 'Hľadať',
  liveBadge: 'Naživo',
  important: 'Najdôležitejšie',

  /// Toolbar ------------------------------------------
  logoText: 'mnt.sk',
  logoTextHtml: 'MNT.<small>SK</small>',
  dennikN: 'DENNÍK',
  linkToDennikN: 'https://dennikn.sk/',
  backToNews: 'Späť',
  buySubscription: 'Kúpiť predplatné',
  linkToSubscription: 'https://predplatne.dennikn.sk',
  login: 'Prihlásiť sa',
  myAccount: 'Moje konto',
  searchToolbar: 'Zadajte hľadaný výraz',
  searchResults: 'Výsledky vyhľadávania',
  mntSK: 'MNT.SK',

  /// Aside --------------------------------------------
  newOnDennikN: 'Nové na Dennikn.sk',
  topNews: 'Najčítanejšie teraz',

  /// Sharing ------------------------------------------
  facebook: 'Facebook',
  messenger: 'Messenger',
  twitter: 'X (Twitter)',
  whatsApp: 'WhatsApp',
  eMail: 'e-Mail',
  copyToClipboard: 'Kopírovať link',
  copiedToClipboard: 'Skopírované',
  shareLiveArticle: 'Zdieľať prenos',

  /// Feed  --------------------------------------------
  readMoreFn: (countOfWords) => {
    return 'Čítať ďalej ' + countOfWords + ' slov';
  },
  /// Live archive -------------------------------------
  liveArchiveTitle: 'Online prenosy',
  liveUpdatedNow: 'Aktualizované teraz',
  liveUpdatedAt: 'Aktualizované o',
  liveHiddenAuthors: 'a ďalší',

  /// message used in SSR
  readMorePlaceholder: 'Čítať ďalej',
  readMoreOnMpm: 'Viac správ na mnt.sk',
  readMoreLiveOnMpm: 'Otvoriť celý prenos',

  /// Widget menu
  newPostsChoiceTitle: 'Aktualizácia minúty',
  autoAddNewPosts: 'automaticky',
  autoAddNewPostsDescription: 'Nové správy sa načítajú automaticky',
  manuallyAddNewPosts: 'manuálne',
  manuallyAddNewPostsDescription: 'Nové správy sa načítajú po kliku',
  linkToMnt: 'https://www.dennikn.sk/minuta?ref=top',

  /// Area strings -------------------------------------
  areaReadMore: 'Čítať ďalej',
  areaCloseMenu: 'Zatvoriť menu',
  areaNewPostsPending: 'Nové správy',

  /// Global strings -----------------------------------
  appIsOffline: 'Bez internetového pripojenia',
  urlNotFound: 'Nesprávna url',
  networkError: 'Nepodarilo sa načítať nové správy',
  noPostsFound: 'Ľutujeme, ale nič tu nie je!',

  /// Page Meta ----------------------------------------
  headTitle: 'Minúta po minúte, rýchle správy Denníka N',
  fbAppId: '1078070992224692',

  /// Whats new modal ----------------------------------
  whatsNewTitle: 'Nová Mnt.sk od Denníka N',
  whatsNewDescription: 'Pripomienky, prosíme, hláste na <a href="mailto:minuta@dennikn.sk">minuta@dennikn.sk</a>',
  whatsNewSubsectionTitle: 'Novinky mnt.sk',
  whatsNewBetterNavigation: 'Lepší prístup k rubrikám a témam cez nové menu',
  whatsNewFasterLoading: 'Rýchlejšie načítavanie a otváranie minút bez odchodu zo stránky',
  whatsNewDarkMode: 'Výber medzi tmavým a svetlým režimom',
  whatsNewSearch: 'Vyhľadávanie',
  whatsNewPreviewSubsectionTitle: 'vzhľad',
  whatsNewLightModeOption: 'Svetlý režim',
  whatsNewDarkModeOption: 'Tmavý režim',
  whatsNewDoneButton: 'hotovo',

  redirectOldURLByHash: [
    {
      type: 'tag',
      fastRecognize: (hash) => hash.indexOf('#/tema/') > -1,
      pullSlug: (hash) => {
        const parts = hash.split('/');
        return parts[parts.length - 1].toLowerCase();
      },
      redirectToURL: (id, slug) => ENV_APP_TAG_ROUTE.replace(':id', id).replace(':tag', slug),
    },
    {
      type: 'category',
      fastRecognize: (hash) => hash.indexOf('#/rubrika/') > -1,
      pullSlug: (hash) => {
        const parts = hash.split('/');
        return parts[parts.length - 1].toLowerCase();
      },
      redirectToURL: (id, slug) => ENV_APP_CATEGORY_ROUTE.replace(':id', id).replace(':category', slug),
    },
  ],
};
