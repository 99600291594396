import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withSWUpdate } from '../ContextProviders/SWUpdateContextProvider';
import { useScrollToTop } from '../ContextProviders/ScrollingHelperContext';
import { useAppContextAppType } from '../ContextProviders/AppContextProvider';

/**
 * Wrapper for <Link component
 * if there is pending SW update, it will create new <a link and reload the page
 *
 * @param {*} props
 * @returns
 */
const SWLink = (props) => {
  const scrollToTop = useScrollToTop();
  const location = useLocation();
  const appType = useAppContextAppType();
  const tryToNavigate = useCallback(
    (ev) => {
      if (location.pathname === props.to) {
        scrollToTop();
        ev.preventDefault();
      }
    },
    [location]
  );

  if (props.newSWReady === true || appType === 'feed') {
    const newProps = { ...props };
    newProps.href = props.to;
    delete newProps.newSWReady;
    delete newProps.to;
    return <a {...newProps} />;
  } else {
    const newProps = { ...props };
    delete newProps.newSWReady;
    return <Link {...newProps} onClick={tryToNavigate} />;
  }
};

export default withSWUpdate(SWLink);
