import React from 'react';
import CurrentPosts from './CurrentPosts';
import TrendingPosts from './TrendingPosts';
import SpriteMap from '../../Components/SpriteMap';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const Aside = ({ messages }) => {
  return (
    <div className={'mnt-Aside'}>
      <div className={'mnt-postBlock'}>
        <div className={'mnt-AsideHeader'}>
          <h3>
            <SpriteMap sprite={'hot'} />
            {messages.topNews}
          </h3>
          <div className={'mnt-HeaderLogo'}>
            <SpriteMap sprite={'logo_N'} />
          </div>
        </div>
        <TrendingPosts />
      </div>
      <div className={'mnt-postBlock'}>
        <div className={'mnt-AsideHeader'}>
          <h3>
            <SpriteMap sprite={'star'} />
            {messages.newOnDennikN}
          </h3>
        </div>
        <CurrentPosts />
      </div>
    </div>
  );
};

export default withMessages(Aside);
