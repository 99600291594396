import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import { canEditSelector, getLiveArticlesSelector } from '../Redux/setupSlice';
import AdminEditLink from './AdminEditLink';
import FeedStickyLiveHeaderBlock from './FeedSticky/FeedStickyLiveHeaderBlock';
import FeedStickyHeaderBlock from './FeedSticky/FeedStickyHeaderBlock';
import FeedStickyLiveHeader from './FeedSticky/FeedStickyLiveHeader';
import { withAppContext } from '../ContextProviders/AppContextProvider';
import { withUserSettingsContext } from '../ContextProviders/UserSettingsContextProvider';

const FeedSticky = ({ stickyContent, messages, appType, dynamicFontSize, sportEnabled }) => {
  const lives = useSelector(getLiveArticlesSelector);
  const [hasHeader, setHasHeader] = useState(stickyContent.name);

  const canEdit = useSelector(canEditSelector);
  const [showEditButton, setShowEditButton] = useState(false);
  const [editUrl, setEditUrl] = useState('');

  useEffect(() => {
    // Header
    setHasHeader(stickyContent.name);

    // Editing
    const types = {
      category: ENV_EDIT_CATEGORY_URL,
      tag: ENV_EDIT_TAG_URL,
      live: ENV_EDIT_LIVE_URL,
      default: '',
    };
    if (!canEdit) {
      setShowEditButton(false);
      return;
    }
    const editUrlByType = types[stickyContent.type] ?? types.default;
    setEditUrl(editUrlByType);
    setShowEditButton(!!editUrlByType);
  }, [canEdit, stickyContent]);

  return (
    <div>
      <div className={`mnt-FeedSticky ${appType === 'feed' && stickyContent.type === 'live' ? '' : 'mnt-border_bottom'}`}>
        {hasHeader && (
          <header className={appType === 'feed' && stickyContent.type === 'live' ? '' : 'mnt-border_bottom'}>
            {stickyContent.type === 'important' && <h1 dangerouslySetInnerHTML={{ __html: messages.important }} />}
            {stickyContent.type === 'lives' && <h1 dangerouslySetInnerHTML={{ __html: messages.menuLive }} />}
            {stickyContent.type !== 'live' && stickyContent.name && <h1 dangerouslySetInnerHTML={{ __html: stickyContent.name }} />}
            {stickyContent.type === 'live' && <FeedStickyLiveHeader content={stickyContent} />}
            {showEditButton && <AdminEditLink url={editUrl} id={stickyContent.id} />}
          </header>
        )}
        {!hasHeader && showEditButton && (
          <header className={appType === 'feed' && stickyContent.type === 'live' ? '' : 'mnt-border_bottom'}>
            <AdminEditLink url={editUrl} id={stickyContent.id} />
          </header>
        )}

        {/* if there are active live articles we need to show them here (only on homepage) */}
        <div>
          {stickyContent.type === 'latest' &&
            lives.map(
              (live) =>
                live.isShown && (
                  <Fragment key={live.id}>
                    {live.isNotSport && <FeedStickyLiveHeaderBlock content={live} />}
                    {!live.isNotSport && sportEnabled && <FeedStickyLiveHeaderBlock content={live} />}
                  </Fragment>
                )
            )}
        </div>

        <div>
          {(stickyContent.excerpt || (stickyContent.tags && stickyContent.tags.length > 0) || (stickyContent.trending_tags && stickyContent.trending_tags.length > 0)) && (
            <FeedStickyHeaderBlock stickyContent={stickyContent} dynamicFontSize={dynamicFontSize} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserSettingsContext(withAppContext(withMessages(FeedSticky)));
