import React from 'react';
import PropTypes from 'prop-types';
import ArticleDateTime from './ArticleDateTime';

const CurrentPost = ({ post }) => {
  return (
    <a href={post.url} className={'mnt-CurrentPost'} target="_blank" rel="noreferrer">
      <ArticleDateTime dateString={post.published_at} />
      {post.title}
    </a>
  );
};

CurrentPost.propTypes = {
  /// Post object from /reader/posts
  post: PropTypes.object.isRequired,
};

export default CurrentPost;
