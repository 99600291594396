/**
 * Reader posts have additional query in the url
 */
export default (urlQuery) => (postObject) => {
  return {
    id: postObject.id,
    image: postObject.image,
    url: postObject.url + urlQuery,
    title: postObject.title,
    published_at: postObject.published_at,
  };
};
