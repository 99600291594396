import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import processLivePost from './mappers/livePostMapper';
import { difference } from '../utils/merging';
import { createSelector } from 'reselect';

/// function that returns unique key for the post
/// used by .filter function
const postIdFn = (post) => post.id;

/**
 * TickPosts action should fetch newest posts for active page
 * parameters are mapped from the current router Match object
 * data should not be merged with state rightaway,
 * but scheduled for further updates
 */
export const tickPosts = createAsyncThunk('lives/tickLives', async ({ routeMatch }, { rejectWithValue }) => {
  try {
    const response = await routeMatch.tick();
    if (response.hasOwnProperty('status') && response.status === 'error') {
      throw new Error(response.code);
    }
    return response;
  } catch (ex) {
    return rejectWithValue(ex.message);
  }
});

/**
 * FetchPosts action can be more customized than tickPosts
 * it will be used mainly for lazyload
 */
export const fetchPosts = createAsyncThunk('lives/fetchLives', async ({ routeMatch, pageNumber }, { rejectWithValue }) => {
  const options = {};
  try {
    return await routeMatch.lazyLoad(options, pageNumber);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const livesSlice = createSlice({
  name: 'lives',
  initialState: {
    lastPageNumber: 0,
    meta: {},
    posts: [],
    pending: {
      meta: {},
      posts: [],
    },
    fetchTimestamp: 0,
    timestamp: 0,
    hasMorePosts: true,
    isLoading: false,
    error: null,
  },
  reducers: {
    applyPendings: (state) => {
      state.posts = [...state.pending.posts, ...difference(state.posts, state.pending.posts, postIdFn)];
      state.meta = state.pending.meta;
      state.pending = { meta: {}, posts: [] };
    },
    setNewPostsResult: (state, { payload }) => {
      state.fetchTimestamp = payload.timestamp;
      state.meta = payload.meta;
      state.timestamp = Math.floor(Date.now() / 1000);
      state.posts = [...payload.lives.map((post) => processLivePost(post, state.meta))];
      state.hasMorePosts = payload.lives.length === payload.meta.count;
      state.pending = { meta: {}, posts: [] };
    },
    preparePendings: (state, { payload }) => {
      state.pending.posts = [...payload.lives.map((post) => processLivePost(post, state.meta)), ...difference(state.pending.posts, payload.lives, postIdFn)];
      state.pending.meta = { ...payload.meta };
    },
    nextPagePostResults: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      /// merging -----------------------------------
      state.posts = [...difference(state.posts, payload.lives, postIdFn), ...payload.lives.map((post) => processLivePost(post, state.meta))];
    },
  },

  extraReducers: {
    [fetchPosts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPosts.rejected]: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [fetchPosts.fulfilled]: (state, { payload }) => {
      livesSlice.caseReducers.nextPagePostResults(state, { payload: payload });

      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },

    [tickPosts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [tickPosts.rejected]: (state, { meta, payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [tickPosts.fulfilled]: (state, { payload }) => {
      if (state.meta.url && state.meta.url === payload.meta.url) {
        livesSlice.caseReducers.preparePendings(state, { payload: payload });
      } else {
        if (payload.timestamp > state.fetchTimestamp) {
          livesSlice.caseReducers.setNewPostsResult(state, { payload: payload });
        }
      }

      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },
  },
});

///
/// helper functions  ----------------------------------------------------------
///

const livesStateSelector = (state) => state.lives;

///
/// Selectors ------------------------------------------------------------------
///

/**
 * @returns Boolean
 */
export const isLoadingSelector = createSelector(livesStateSelector, (state) => state.isLoading);

/**
 * Are there new posts pending to be injected to the feed?
 * @returns Boolean
 */
export const countOfPendingPostsSelector = createSelector(livesStateSelector, (state) => {
  try {
    if (state.pending.posts.length > 0 && state.pending.posts[0].id !== state.posts[0].id) {
      const pendingIds = state.pending.posts.map((post) => post.id);
      const currentIds = state.posts.map((post) => post.id);
      return pendingIds.filter((pendingId) => currentIds.indexOf(pendingId) === -1).length;
    }
  } catch (ex) {}
  return 0;
});

export const hasPostsSelector = createSelector(livesStateSelector, (state) => state.posts.length > 0);
export const metaSelector = createSelector(livesStateSelector, (state) => state.meta);
export const postsSelector = createSelector(livesStateSelector, (state) => state.posts);
export const hasMorePostsSelector = createSelector(livesStateSelector, (state) => state.hasMorePosts);

export const hasErrorSelector = createSelector(livesStateSelector, (state) => state.error !== null);
export const errorMessageSelector = createSelector(livesStateSelector, (state) => state.error);
export const lastTimestampSelector = createSelector(livesStateSelector, (state) => state.timestamp);

export const { applyPendings } = livesSlice.actions;

export default livesSlice.reducer;
