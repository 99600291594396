import { useState } from 'react';

export default function useScrollableAncestor(appType) {
  const [ancestor, setAncestor] = useState(() => {
    if (appType === 'app' || appType === 'feed') return 'window';
    try {
      return document.querySelector('.mnt-Layout.mnt-layout--widget');
    } catch (ex) {
      /// SSR
      return 'window';
    }
  });

  /// on first render, there is no matching element, so we are trying to find it again here..
  if (appType === 'widget' && ancestor === null) {
    const el = document.querySelector('.mnt-Layout.mnt-layout--widget');
    if (el !== null) setAncestor(el);
  }

  return ancestor;
}
