import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArticleDateTime = ({ dateString, className }) => {
  const dateObject = new Date(dateString);

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  let dateTimeString = '';

  const leadingZeros = (number) => {
    return ('00' + number).substr(-2);
  };

  if (dateObject.toDateString() === today.toDateString()) {
    dateTimeString = '';
  } else if (dateObject.toDateString() === yesterday.toDateString()) {
    dateTimeString = 'Včera';
  } else {
    dateTimeString =
      leadingZeros(new Intl.DateTimeFormat('sk-SK', { day: '2-digit', timeZone: 'Europe/Bratislava' }).format(dateObject).slice(0, -1)) +
      '.' +
      leadingZeros(new Intl.DateTimeFormat('sk-SK', { month: '2-digit', timeZone: 'Europe/Bratislava' }).format(dateObject).slice(0, -1));
  }
  const hours = new Intl.DateTimeFormat([], { hour: '2-digit', timeZone: 'Europe/Bratislava', hour12: false }).format(dateObject);
  const midnightCorrectHours = hours === '24' ? '00' : hours;
  dateTimeString += ' ' + leadingZeros(midnightCorrectHours) + ':' + leadingZeros(new Intl.DateTimeFormat('sk-SK', { minute: '2-digit', timeZone: 'Europe/Bratislava' }).format(dateObject));

  return <span className={`mnt-time ${className ? className : ''}`}>{dateTimeString}</span>;
};

ArticleDateTime.propTypes = {
  dateString: PropTypes.string.isRequired,
};

export default memo(ArticleDateTime);
