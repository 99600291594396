import React, { useContext, useEffect, useState } from 'react';
import Toast from '../Components/Toast';

const ToastContext = React.createContext();
const useToastContext = () => useContext(ToastContext);

const ToastContextProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState('');
  const [isVisible, setVisibility] = useState(false);
  const [showAnimationClass, setAnimationClass] = useState(false);

  const [timeoutHandle, setTimeoutHandle] = useState(null);
  const [animationTimeoutHandle, setAnimationTimeoutHandle] = useState(null);

  const showToast = (message) => {
    setToastMessage(message);
    setVisibility(true);
    setAnimationClass(true);
    setTimeoutHandle(setTimeout(() => hideToast(), ENV_TOAST_VISIBLE_FOR_SECONDS * 1000));
  };

  const hideToast = () => {
    setAnimationClass(false);
    setAnimationTimeoutHandle(
      setTimeout(() => {
        setVisibility(false);
        if (timeoutHandle !== null) {
          clearTimeout(timeoutHandle);
          setTimeoutHandle(null);
        }
      }, 300)
    );
  };

  useEffect(() => {
    return () => {
      if (timeoutHandle !== null) clearTimeout(timeoutHandle);
      if (animationTimeoutHandle !== null) clearTimeout(animationTimeoutHandle);
    };
  }, []);

  return (
    <ToastContext.Provider
      value={{
        showToast: showToast,
      }}
    >
      <>
        {children}
        <div className={'mnt-ToastContainer' + (showAnimationClass ? ' start' : '')}>
          <div className="mnt-ToastCenter">{isVisible && <Toast>{toastMessage}</Toast>}</div>
        </div>
      </>
    </ToastContext.Provider>
  );
};

const withToast = (Component) => (props) => {
  const toast = useToastContext();

  return <Component {...props} showToast={toast.showToast} />;
};

export default ToastContextProvider;
export { useToastContext, withToast };
