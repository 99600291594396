import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CurrentPostsSkeleton = ({ repeat }) => {
  return (
    <SkeletonTheme color="#222222" highlightColor="#444">
      {new Array(repeat).fill(0).map((_, i) => (
        <a key={i} className={'mnt-CurrentPost'}>
          <span className={'mnt-time'}>
            <Skeleton width={'50px'} />
          </span>
          <p>
            <Skeleton count={2} />
            <Skeleton width={'70%'} />
          </p>
        </a>
      ))}
    </SkeletonTheme>
  );
};

export default CurrentPostsSkeleton;
