import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import SWLink from './SWLink';

const CategoryLink = ({ id, slug, children, isActive, additionalProps }) => {
  const url = generatePath(ENV_APP_CATEGORY_ROUTE, { id: id, category: slug });

  if (isActive === true) {
    if (additionalProps.hasOwnProperty('className')) additionalProps.className += ' active';
    else additionalProps.className = 'active';
  }

  return (
    <SWLink to={url} {...additionalProps}>
      {children}
    </SWLink>
  );
};

CategoryLink.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  additionalProps: PropTypes.object,
};

export default memo(CategoryLink);
