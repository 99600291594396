import { useDispatch } from 'react-redux';
import { markPostAsRead } from '../../Redux/postsSlice';

export default function useMarkAsReadWaypoint() {
  const dispatch = useDispatch();

  return (postId) => (onLeaveEvent) => {
    if (onLeaveEvent.currentPosition === 'above' || onLeaveEvent.currentPosition === 'below') {
      dispatch(markPostAsRead({ postId: postId }));
    }
  };
}
