import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Ticker from '../../Components/Controllers/Ticker';
import { matchUrl } from '../Routes';
import { fetchSearch, hasMorePostsSelector, hasErrorSelector, isLoadingSelector, postsSelector, tagsSelector, tickSearch } from '../../Redux/searchSlice';
import FeedArticle from '../../Components/FeedArticle';
import TagLink from '../../Components/TagLink';
import { withScrollingHelperContext } from '../../ContextProviders/ScrollingHelperContext';
import FeedArticleSkeleton from '../../Components/Skeletons/FeedArticleSkeleton';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import { Waypoint } from 'react-waypoint';
import useFeedConfiguration from '../../Components/Hooks/useFeedConfiguration';
import useScrollableAncestor from '../../Components/Hooks/useScrollableAncestor';
import EndOfFeed from '../../Components/EndOfFeed';
import MetaData from '../../Components/MetaData';
import { shareMenuVisibleForArticleIdSelector, toggleShareMenuForArticle } from '../../Redux/appSlice';

const Search = ({ scrollToTop, messages, appType }) => {
  const feedConfiguration = useFeedConfiguration();
  const location = useLocation();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const [searchTerm, setSearchTerm] = useState('');

  const showShareMenuForArticleId = useSelector(shareMenuVisibleForArticleIdSelector);
  const isLoading = useSelector(isLoadingSelector);
  const hasMorePosts = useSelector(hasMorePostsSelector);
  const tags = useSelector(tagsSelector);
  const posts = useSelector(postsSelector);
  const hasError = useSelector(hasErrorSelector);
  const scrollableAncestor = useScrollableAncestor(appType);
  const [searchMeta, setSearchMeta] = useState({ name: routeParams.query });

  /**
   * method to be used by <Ticker component
   */
  const onTick = useCallback(() => {
    const routeMatch = matchUrl(location.pathname, appType);
    searchTerm === routeParams.query && dispatch(tickSearch({ routeMatch, routeParams }));
  }, [searchTerm]);

  useEffect(() => {
    setSearchMeta({ name: routeParams.query });
    setSearchTerm(routeParams.query);
    scrollToTop();
  }, [routeParams]);

  const waypointOnEnter = () => {
    if (isLoading || (hasError && Date.now() / 1000 - lastTimestamp < 10)) return;
    if (posts.length === 0) return;
    const routeMatch = matchUrl(location.pathname, appType);

    dispatch(
      fetchSearch({
        routeMatch,
        routeParams,
        beforeId: posts[posts.length - 1].id,
        full: true,
      })
    );
  };

  return (
    <div className={'mnt-Feed'}>
      {appType === 'app' && <MetaData metaData={searchMeta} />}

      <Ticker onTick={onTick} onlyOnce={true} skipFirst={false} tickerSpeedSeconds={ENV_POSTS_TICKER_SPEED} fireOnChange={[searchTerm]} lastTimestamp={0} timeoutAfterSeconds={0} />

      {isLoading && <FeedArticleSkeleton repeat={5} />}

      {isLoading && <FeedArticleSkeleton repeat={5} />}

      <div className={'mnt-Search'}>
        <h1>
          {messages.searchResults}: “{searchTerm}"
        </h1>
        {tags.length > 0 && (
          <div className={'mnt-FeedArticle'}>
            <div className="mnt-toolbar">
              <span className="mnt-time">témy</span>
            </div>
            <nav className={'mnt-tags searches'}>
              {tags.map((tag) => (
                <TagLink key={tag.id} id={tag.id} slug={tag.slug} isHighlighted={false}>
                  {tag.name}
                </TagLink>
              ))}
            </nav>
            {/* <div className={'mnt-toolbar'}>
                <span className={'mnt-time'}>{'Rubriky'}</span>
              </div> */}
            {/* @TODO - komponenta Sections je nastylovana treba ju dorobit */}
            {/* zatial nemame data na zobrazovanie */}
            {/* <Sections /> */}
          </div>
        )}
        <div>
          {posts.map((article) => (
            <FeedArticle article={article} key={article.id} seen={true} toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))} showShareMenu={showShareMenuForArticleId === article.id} />
          ))}
        </div>
        {posts.length === 0 && (
          <div className="mnt-FeedArticle">
            <h3>{messages.noPostsFound}</h3>
          </div>
        )}
        {feedConfiguration.lazyLoad && !isLoading && hasMorePosts && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
        {/** one more skeleton at the enf of the page */}
        {feedConfiguration.lazyLoad && isLoading && hasMorePosts && <FeedArticleSkeleton />}
        {/** end of the feed... */}
        {!hasMorePosts && <EndOfFeed />}
      </div>
    </div>
  );
};

export default withScrollingHelperContext(withAppContext(withMessages(Search)));
