import React, { useContext, useEffect, useState } from 'react';

const NetworkStateContext = React.createContext({});
const useNetworkStateContext = () => useContext(NetworkStateContext);

/// Wrap application / widget to provide network state to its children components
const NetworkStateContextProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  const onlineEventListener = () => {
    setIsOnline(true);
  };

  const offlineEventListener = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', onlineEventListener);
    window.addEventListener('offline', offlineEventListener);

    /// unregister event listeners
    return () => {
      window.removeEventListener('online', onlineEventListener);
      window.removeEventListener('offline', offlineEventListener);
    };
  });

  return <NetworkStateContext.Provider value={isOnline}>{children}</NetworkStateContext.Provider>;
};

/// HigherOrder component that will inject network state(isOnline) to Component.props
const withNetworkState = (Component) => (props) => {
  const isOnline = useNetworkStateContext();

  return (
    /// adds isOnline property to the <Component
    <Component {...props} isOnline={isOnline} />
  );
};

export default NetworkStateContextProvider;
export { useNetworkStateContext, withNetworkState };
