export default (embedObject) => {
  const embed = { ...embedObject };
  if (!embed.hasOwnProperty('type')) {
    embed.type = findMatchingEmbedType(embed.link);
  }
  return embed;
};

function findMatchingEmbedType(link) {
  if (link === undefined) return '';
  let matches = link.match(/^(https?:\/\/)?(www\.)?instagram.com\/(.+)/);
  if (matches !== null) return 'instagram';
  matches = link.match(/^(https?:\/\/)?(www\.)?facebook.com\/(.+)\/videos\/(.+)\//);
  if (matches !== null) return 'facebook-video';
  matches = link.match(/^(https?:\/\/)?(www\.)?facebook.com\/(.+)/);
  if (matches !== null) return 'facebook';
  matches = link.match(/^(https?:\/\/)?(www\.)?twitter.com\/(.+)/);
  if (matches !== null) return 'twitter';
  matches = link.match(/^(https?:\/\/)?(www\.)?youtube.com\/(.+)/);
  if (matches !== null) return 'youtube';
  matches = link.match(/^(https?:\/\/)?(www\.)?vimeo.com\/(.+)/);
  if (matches !== null) return 'vimeo';
  matches = link.match(/^(https?:\/\/)?(.+)\.podbean.com\/(.+)/);
  if (matches !== null) return 'podbean';
  matches = link.match(/^(https?:\/\/)?(www\.)?soundcloud.com\/(.+)/);
  if (matches !== null) return 'soundcloud';
}
