import embedMapper from './embedMapper';

const internalLinksRegex = /<a .*?>/g;

export default (postObject, meta) => {
  const post = {
    excerpt: !!postObject.excerpt ? postObject.excerpt : '',
    content: !!postObject.content ? postObject.content : '',
    id: !!postObject.id ? postObject.id : 0,
    url: !!postObject.url ? postObject.url : '',
    published_at: !!postObject.published_at ? postObject.published_at : '',
    tags: !!postObject.tags ? postObject.tags : null,
    image: !!postObject.image ? postObject.image : null,
    title: !!postObject.title ? postObject.title : '',
    embed: !!postObject.embed ? [...postObject.embed.map(embedMapper)] : null,
    seen: false,
    /// should resolve multiple ways of feed viewing
    /// it can be seen on mnt.sk but not on dennikn.sk
    /// in such a case we shell force not seen flag
    forceNotSeen: false,
  };
  if (
    postObject.excerpt === undefined ||
    postObject.content === undefined ||
    postObject.id === undefined ||
    postObject.url === undefined ||
    postObject.published_at === undefined ||
    postObject.tags === undefined ||
    postObject.image === undefined ||
    postObject.title === undefined ||
    postObject.embed === undefined
  ) {
    console.log('API RETURNED INVALID OBJECT');
  }

  post.content = fixInternalExternalDataAttr(post.content);
  post.excerpt = fixInternalExternalDataAttr(post.excerpt);

  if (APP_TYPE === 'app') {
    post.excerpt = post.excerpt.replace(/data-link="ext"/g, 'data-link="ext" target="_blank" rel="noreferrer"');
    post.content = post.content.replace(/data-link="ext"/g, 'data-link="ext" target="_blank" rel="noreferrer"');
    post.excerpt = post.excerpt.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mwat"');
    post.content = post.content.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mwat"');
  } else if (APP_TYPE === 'widget') {
    post.excerpt = post.excerpt.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mpm"');
    post.content = post.content.replace(/data-link="int" href="(.*?)"/g, 'data-link="int" href="$1?ref=mpm"');
    post.excerpt = post.excerpt.replace(/data-link="ext"/g, 'data-link="ext" target="_blank" rel="noreferrer"');
    post.content = post.content.replace(/data-link="ext"/g, 'data-link="ext" target="_blank" rel="noreferrer"');
  }

  if (!post.hasOwnProperty('published_at_date')) {
    post.published_at_date = new Date(post.published_at);
    post.published_at_date = post.published_at_date.getTime();
  }

  if (meta.type === 'tag' || meta.type === 'newsfilter') {
    post.tags = post.tags.filter((tag) => tag.id !== meta.id);
  }

  if (!post.hasOwnProperty('isImportant')) {
    post.isImportant = false;
    if (postObject.settings) {
      post.isImportant = postObject.settings.indexOf('important') > -1;
    }
  }

  if (!post.hasOwnProperty('isCollapsed')) {
    post.isCollapsed = false;
    if (postObject.settings) {
      post.isCollapsed = postObject.settings.indexOf('collapsed') > -1;
    }
  }

  if (!post.hasOwnProperty('shouldHideImageOnHome')) {
    post.shouldHideImageOnHome = false;
    if (postObject.settings) {
      post.shouldHideImageOnHome = postObject.settings.indexOf('hide_image_on_home') > -1;
    }
  }

  /// hasReadMore can be dynamically switched now
  /// so we need to preserve original excerpt
  post.excerptOriginal = post.excerpt;

  if (!post.hasOwnProperty('hasReadMore')) {
    post.hasReadMore = post.content.length > 0;
    if (post.hasReadMore) {
      /// if post has "read more" button, we will replace <a tag with <strong
      post.excerpt = post.excerpt.replace(/<a.*?>(.*?)<\/a>/, (matched, p1) => '<strong>' + p1 + '</strong>');
      let cont = post.content;
      cont = cont.replace(/<[^>]*>/g, ' ');
      cont = cont.replace(/\s+/g, ' ');
      cont = cont.trim();
      post.readMoreCountOfWords = cont.split(' ').length;
    }
  }

  return post;
};

export function fixInternalExternalDataAttr(content) {
  let matchInternalLinks;
  while ((matchInternalLinks = internalLinksRegex.exec(content)) !== null) {
    let isInternal = false;
    for (let x of ENV_INTERNAL_LINKS) {
      if (matchInternalLinks[0].indexOf(x) > -1) isInternal = true;
    }
    if (!isInternal) {
      let switchedToExternal = matchInternalLinks[0].replace('data-link="int"', 'data-link="ext"');
      content = content.replace(matchInternalLinks[0], switchedToExternal);
    }
  }
  return content;
}
