class TickController {
  constructor(seconds, onTick) {
    this.seconds = seconds;
    this.onTick = onTick;
    this.currentTimeoutObject = null;
  }

  fireTicker() {
    this.stopTicker();
    this.onTick();
    this.scheduleTicker();
  }

  stopTicker() {
    this.currentTimeoutObject && clearTimeout(this.currentTimeoutObject);
  }

  scheduleTicker() {
    this.currentTimeoutObject = setTimeout(() => this.fireTicker(), this.seconds * 1000);
  }
}

export { TickController };
