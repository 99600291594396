import React, { useContext } from 'react';

const GTMTrackerContext = React.createContext({});
const useGTMTrackerContext = () => useContext(GTMTrackerContext);

const GTMTrackerContextProvider = ({ children }) => {
  const trackGTMEvent = (options) => {
    if (ENV_GA4ID !== false && window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(options);
    }
  };

  const trackReadMore = (articleId) => {
    trackGTMEvent({
      event: 'read_more',
      article_id: articleId,
    });
  };

  const trackCategory = (categorySlug) => {
    trackGTMEvent({
      event: 'nav_category',
      category: categorySlug,
    });
  };

  const trackTag = (tagSlug) => {
    trackGTMEvent({
      event: 'nav_tag',
      tag: tagSlug,
    });
  };

  const trackImportant = () => {
    trackGTMEvent({
      event: 'nav_important',
    });
  };

  const trackSportToggle = (newState) => {
    trackGTMEvent({
      event: 'toggle_sport',
      toggle_state: newState,
    });
  };

  const trackThemeToggle = (newTheme) => {
    trackGTMEvent({
      event: 'toggle_darkmode',
      toggle_state: newTheme,
    });
  };

  const trackFetchNewPosts = () => {
    trackGTMEvent({
      event: 'fetch_new_posts',
    });
  };

  const trackMoreTopics = () => {
    trackGTMEvent({
      event: 'moretopics',
    });
  };

  const trackUserLogin = (id, status) => {
    trackGTMEvent({
      event: 'logged_in',
      user_id: id,
      logged_in: status,
    });
  };

  const trackIfUsesPwa = (status) => {
    trackGTMEvent({
      event: 'uses_pwa',
      app_type: status ? 'pwa' : 'web',
    });
  };

  const trackPwaPromptAppeared = () => {
    trackGTMEvent({
      event: 'pwa_install_prompt_appeared',
    });
  };

  const trackPwaPromptClicked = (status) => {
    trackGTMEvent({
      event: 'pwa_install_prompt_clicked',
      pwa_install_prompt_status: status,
    });
  };

  const trackPageLoaded = () => {
    trackGTMEvent({
      event: 'mnt_page_loaded',
    });
  };

  return (
    <GTMTrackerContext.Provider
      value={{
        /// events ------------------------------
        trackGTMEvent,
        trackReadMore,
        trackCategory,
        trackTag,
        trackImportant,
        trackThemeToggle,
        trackSportToggle,
        trackFetchNewPosts,
        trackMoreTopics,
        trackUserLogin,
        trackPwaPromptAppeared,
        trackPwaPromptClicked,
        trackIfUsesPwa,
        trackPageLoaded,
      }}
    >
      {children}
    </GTMTrackerContext.Provider>
  );
};

const withGTMMTrackerContext = (Component) => (props) => {
  const value = useGTMTrackerContext();
  return (
    <Component
      /// tracking methods
      {...props}
      trackGTMEvent={value.trackGTMEvent}
      trackReadMore={value.trackReadMore}
      trackCategory={value.trackCategory}
      trackTag={value.trackTag}
      trackImportant={value.trackImportant}
      trackThemeToggle={value.trackThemeToggle}
      trackSportToggle={value.trackSportToggle}
      trackFetchNewPosts={value.trackFetchNewPosts}
      trackMoreTopics={value.trackMoreTopics}
      trackUserLogin={value.trackUserLogin}
      trackPwaPromptAppeared={value.trackPwaPromptAppeared}
      trackPwaPromptClicked={value.trackPwaPromptClicked}
      trackIfUsesPwa={value.trackIfUsesPwa}
      trackPageLoaded={value.trackPageLoaded}
    />
  );
};

export default GTMTrackerContextProvider;
export { useGTMTrackerContext, withGTMMTrackerContext };
