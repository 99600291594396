import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import AppShell from './Containers/AppShell';
import createNewStore from './Redux/store';

import SentryInit from './sentry_init';
SentryInit();

const store = createNewStore();
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(<AppShell RouterComponent={Router} routerConfig={{}} storeObject={store} />, document.getElementById('app'));
