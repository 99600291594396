import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useHashFeedPosition(posts) {
  const location = useLocation();

  const [targetId, setTargetId] = useState(() => {
    if (location.hash) {
      const matched = /#(\d+)/.exec(location.hash);
      if (matched && matched.length > 1) {
        window.scrollRestoration = 'manual';
        return matched[1];
      }
    }
    return null;
  });

  useEffect(() => {
    if (targetId === null) return;
    if (posts && posts.length > 10 && posts.length < 100) {
      if (posts.filter((post) => post.id == targetId).length > 0) {
        setTimeout(() => {
          const foundTarget = document.getElementById(targetId);
          if (foundTarget) {
            foundTarget.scrollIntoView(true);
            // window.scrollTo({ top: foundTarget.offsetTop });
            setTargetId(null);
            setTimeout(() => (window.scrollRestoration = 'auto'), 50);
          }
        }, 0);
      }
    }
  }, [posts]);
}
