import React, { useContext, useEffect, useState } from 'react';

const SWUpdateContext = React.createContext({});
const useSWUpdateContext = () => useContext(SWUpdateContext);

const SWUpdateContextProvider = ({ children }) => {
  const [newSWReady, setNewSWReady] = useState(false);

  useEffect(() => {
    if ('__NEW_SW_READY__' in window && window.__NEW_SW_READY__ === true) {
      setNewSWReady(true);
    } else {
      window.newSWReadyCallback = () => {
        setNewSWReady(true);
      };
      return () => delete window.newSWReadyCallback;
    }
  }, []);

  return <SWUpdateContext.Provider value={{ newSWReady: newSWReady }}>{children}</SWUpdateContext.Provider>;
};

const withSWUpdate = (Component) => (props) => {
  const { newSWReady } = useSWUpdateContext();

  return <Component {...props} newSWReady={newSWReady} />;
};

export default SWUpdateContextProvider;
export { useSWUpdateContext, withSWUpdate };
