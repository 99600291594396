import React, { memo } from 'react';

const TrendingPost = ({ post }) => {
  const hasImage = post.image && post.image.sizes.length > 0;
  let image = false;
  let imageExcerpt = '';

  if (hasImage) {
    imageExcerpt = post.image.excerpt;
    image = post.image.sizes.reduce((acc, current) => {
      if (acc === false || acc.width > current.width) acc = current;
      return acc;
    }, false);
  }

  return (
    <a href={post.url} className={'mnt-TrendingPost'} target="_blank" rel="noreferrer">
      {post.title}
      {hasImage && (
        <span className={'mnt-PostImage'}>
          <img src={image.url} alt={imageExcerpt} width={'110'} height={'73'} />
        </span>
      )}
    </a>
  );
};

export default TrendingPost;
