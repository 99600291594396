import React, { memo } from 'react';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import useUserHasSubscription from './Hooks/useUserSubscriptions';
import SpriteMap from './SpriteMap';
import { getCookie, setCookie } from 'react-use-cookie';
import { withUserSettingsContext } from '../ContextProviders/UserSettingsContextProvider';

const ToolbarActions = ({ messages }) => {
  const subscriber = useUserHasSubscription();
  const nToken = getCookie(ENV_COOKIE_N_TOKEN);
  const openLoginForm = () => {
    if (!nToken) {
      CrmLogin.init(
        {
          modal: true,
          mode: 'registration',
          crmBaseUrl: ENV_CRM_BASE_URL,
          crmCookieDomain: ENV_CRM_COOKIE_DOMAIN,
          redirectUrl: window.location.href,
          ajax: true,
          hiddenInputs: {
            source: 'mpm_mnt',
          },
          locale: `${messages.longCode}`,

          login: {
            showLogo: true,
            showRegistrationLink: true,
            ajaxSuccessCallback: (j) => {
              setCookie(ENV_COOKIE_N_TOKEN, `${j.access.token}`, { SameSite: 'Lax', Secure: true });
              location.reload();
            },
          },
          registration: {
            showLogo: true,
            ajaxUrl: '/api/v1/users/public-create',
            ajaxSuccessCallback: (j) => {
              setCookie(ENV_COOKIE_N_TOKEN, `${j.access.token}`, { SameSite: 'Lax', Secure: true });
              location.reload();
            },
          },
        },
        document.getElementById('login'),
        true
      );
      return;
    }
    window.location = ENV_CRM_LOCATION;
  };

  return (
    <div className={'mnt-ToolbarActions'}>
      {subscriber === false && (
        <a href={messages.linkToSubscription} className={'mnt-btn mnt-green'} title={messages.buySubscription} target="_blank" rel="noreferrer">
          {messages.buySubscription}
        </a>
      )}
      <button className={!nToken && !subscriber ? 'mnt-login-cta' : 'mnt-my-account-cta'} title={!nToken ? messages.login : messages.myAccount} onClick={openLoginForm}>
        <SpriteMap sprite={'user'} />
        {!nToken ? messages.login : messages.myAccount}
      </button>
    </div>
  );
};

export default withUserSettingsContext(withMessages(memo(ToolbarActions)));
