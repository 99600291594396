/*******************************************************************************
 * Setup Slice
 * -----------------------------------------------------------------------------
 * ACTIONS
 *  fetchSetup
 *
 * SELECTORS:
 *  shouldUpdateSetupData
 *  getLatestMenuItem
 *  getImpotantMenuItem
 *  getLiveMenuItem
 *  getCategoriesMenuItems
 ******************************************************************************/
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { executeSetupCall } from '../api_definitions';
import tagMapper from './mappers/tagMapper';
import processLivePost from './mappers/livePostMapper';

export const fetchSetup = createAsyncThunk('setup/fetchSetup', async (_, { rejectWithValue }) => {
  try {
    return await executeSetupCall();
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const setupSlice = createSlice({
  name: 'setup',
  initialState: {
    menu: [],
    authors: [],
    tags: [],
    featured_tags: [],
    feature: null,
    feature_test: null,
    assets: [],
    lives: [],
    timestamp: 0,
    fetchTimestamp: 0,
    requestId: null,
    error: null,
    isLoading: false,
    /// if capabilities contain "edit" string
    canEdit: false,
  },
  reducers: {
    fulfilledFetchSetup: (state, action) => {
      state.fetchTimestamp = action.payload.timestamp;
      state.menu = action.payload.menu;
      // state.authors = action.payload.authors;
      if (state.tags.length === 0) {
        state.tags = action.payload.tags.slice(0, 6).map(tagMapper);
      }

      // state.featured_tags = action.payload.featured_tags;
      state.feature = ENV_USE_FEATURED_TEST_DATA ? action.payload.feature_test : action.payload.feature;
      state.assets = action.payload.assets;
      state.lives = ENV_SHOW_LIVES ? action.payload.lives.map(processLivePost) : [];
      state.canEdit = action.payload.capabilities ? action.payload.capabilities.indexOf('edit') > -1 : false;
    },

    replaceTags: (state, action) => {
      state.tags = action.payload.tags;
    },

    saveSsrRequestData: (state, action) => {
      state.canEdit = action.payload.ssrReqCapabilities ? action.payload.ssrReqCapabilities.indexOf('edit') > -1 : false;
    },
  },
  extraReducers: {
    [fetchSetup.fulfilled]: (state, action) => {
      if (action.payload.timestamp > state.fetchTimestamp) {
        setupSlice.caseReducers.fulfilledFetchSetup(state, action);
        state.error = null;
        state.isLoading = false;
        state.timestamp = Math.floor(Date.now() / 1000);
      }
    },
    [fetchSetup.pending]: (state, { meta }) => {
      state.isLoading = true;
      state.requestId = meta;
      state.error = null;
    },
    [fetchSetup.rejected]: (state, { meta, payload }) => {
      state.isLoading = false;
      state.requestId = meta;
      state.error = payload;
      state.timestamp = Math.floor(Date.now() / 1000);
    },
  },
});

/// helper functions  ----------------------------------------------------------

const setupStateSelector = (state) => state.setup;
const mainMenuSelector = (state) => state.setup.menu;
const setupTags = (state) => state.setup.tags;
const setupLives = (state) => state.setup.lives;

const findFirstByType = (type) => (list) => {
  const res = list.filter((it) => it.type === type);
  return res.length > 0 ? res[0] : null;
};

/// Selectors ------------------------------------------------------------------

export const lastTimestampSelector = createSelector(setupStateSelector, (state) => state.timestamp);
export const featureSelector = createSelector(setupStateSelector, (state) => state.feature);

/**
 * returns single element or null
 * ex. { "name": "Hlavn\u00e9", "type": "latest" }
 */
export const getLatestMenuItem = createSelector(mainMenuSelector, findFirstByType('latest'));

/**
 * returns single element or null
 * ex. { "name": "Dôležité", "type": "important" }
 */
export const getImpotantMenuItem = createSelector(mainMenuSelector, findFirstByType('important'));

/**
 * returns single element or null
 * ex. { "name": "Dôležité", "type": "important" }
 */
export const getLiveMenuItem = createSelector(mainMenuSelector, findFirstByType('lives'));

export const getLiveArticlesSelector = createSelector(setupLives, (lives) => lives);

/**
 * Returns ALL categories found as an array
 * [
 *  ...
 *  { "id", "url", "name", "type" }
 *   ...
 * ]
 */
export const getCategoriesMenuItems = createSelector(mainMenuSelector, (menu) => {
  const regexSlug = /\/([\w-_]+)\/?$/;

  return menu
    .filter((it) => it.type === 'category')
    .map((it) => {
      const matched = regexSlug.exec(it.url);
      const slug = matched !== null ? matched[1] : '';

      return {
        id: it.id,
        name: it.name,
        slug: slug,
      };
    });
});

export const getTagsMenuItems = createSelector(setupTags, (tags) =>
  tags.map((tag) => ({
    id: tag.id,
    slug: tag.slug,
    name: tag.name,
    settings: tag.settings,
  }))
);

export const isLoadingSelector = createSelector(setupStateSelector, (state) => state.isLoading);

export const canEditSelector = createSelector(setupStateSelector, (state) => state.canEdit);

export const { fulfilledFetchSetup, replaceTags, saveSsrRequestData } = setupSlice.actions;

export default setupSlice.reducer;
