import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userDataRequestPassedSelector, userSubscriptionsSelector } from '../../Redux/appSlice';

/**
 * if value is null, it means that call to user.php API point is not finished yet
 *
 * @returns true | false | null
 */
export default function useUserHasSubscription() {
  const [hasSubscription, setHasSubsciption] = useState(null);
  const subscriptions = useSelector(userSubscriptionsSelector);
  const finishedLoading = useSelector(userDataRequestPassedSelector);

  useEffect(() => {
    /// still loading..
    if (finishedLoading === false) return;
    /// there are no subscriptions available
    if (subscriptions.length === 0) {
      setHasSubsciption(false);
      return;
    }
    const now = Date.now() / 1000;
    const active = subscriptions.filter((sub) => sub.start_time < now && sub.end_time > now).length;
    setHasSubsciption(active > 0);
  }, [subscriptions, finishedLoading]);

  return hasSubscription;
}
