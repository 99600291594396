import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SpriteMap from './SpriteMap';
import { toggleMobileMenu } from '../Redux/appSlice';
import { useDispatch } from 'react-redux';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import { getCookie, setCookie } from 'react-use-cookie';
import useUserHasSubscription from './Hooks/useUserSubscriptions';

const MenuSearchBox = ({ onSearchConfirm, messages }) => {
  const subscriber = useUserHasSubscription();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const nToken = getCookie(ENV_COOKIE_N_TOKEN);

  const openLoginForm = () => {
    if (!nToken) {
      CrmLogin.init(
        {
          modal: true,
          mode: 'registration',
          crmBaseUrl: ENV_CRM_BASE_URL,
          crmCookieDomain: ENV_CRM_COOKIE_DOMAIN,
          redirectUrl: window.location.href,
          ajax: true,
          hiddenInputs: {
            source: 'mpm_mnt',
          },
          locale: `${messages.longCode}`,

          login: {
            showLogo: true,
            showRegistrationLink: true,
            ajaxSuccessCallback: (j) => {
              setCookie(ENV_COOKIE_N_TOKEN, `${j.access.token}`, { SameSite: 'Lax', Secure: true });
              location.reload();
            },
          },
          registration: {
            showLogo: true,
            ajaxUrl: '/api/v1/users/public-create',
            ajaxSuccessCallback: (j) => {
              setCookie(ENV_COOKIE_N_TOKEN, `${j.access.token}`, { SameSite: 'Lax', Secure: true });
              location.reload();
            },
          },
        },
        document.getElementById('login'),
        true
      );
      return;
    }
    window.location = ENV_CRM_LOCATION;
  };

  const onClearClicked = (event) => {
    dispatch(toggleMobileMenu());
    event.preventDefault();
    return false;
  };

  const searchSubmitHandler = (event) => {
    event.preventDefault();
    onSearchConfirm(inputValue);
  };

  return (
    <div className={'mnt-menuToolbar'}>
      <form className={'mnt-MenuSearchBox'} onSubmit={searchSubmitHandler}>
        <input type="text" placeholder={messages.search} value={inputValue} onChange={(event) => setInputValue(event.target.value)} />
        <button aria-label={'search'}>
          <SpriteMap sprite={'search'} />
        </button>
      </form>
      <button onClick={openLoginForm} className={!nToken && !subscriber ? 'mnt-user mnt-user-logged-out' : 'mnt-user mnt-user-logged-in'} aria-label={!nToken ? messages.login : messages.myAccount}>
        <SpriteMap sprite={'user'} />
      </button>
      <button className={'mnt-close'} onClick={onClearClicked} aria-label={messages.areaCloseMenu}>
        <SpriteMap sprite={'clear'} />
      </button>
    </div>
  );
};

MenuSearchBox.propTypes = {
  onSearchConfirm: PropTypes.func.isRequired,
};

export default withMessages(MenuSearchBox);
