import React, { useCallback, useContext, useState } from 'react';
import { withMessages } from '../ContextProviders/LanguageContextProvider';

const ContinueOnMntContext = React.createContext({});
const useContinueOnMntContext = () => useContext(ContinueOnMntContext);

const ContinueOnMntContextProvider = ({ messages, children }) => {
  const [jumpToPathname, setPathname] = useState(() => WEBAPP_ROOT_PATH);
  const [buttonLabel, setButtonLabel] = useState(() => messages.readMoreOnMpm);

  const jumpToMnt = useCallback(() => {
    const articles = [...document.getElementsByClassName('js-hook-feed-article')];
    const app = document.getElementById('app');
    let lastVisible = articles[0];
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].offsetTop + articles[i].offsetHeight < app.offsetHeight) lastVisible = articles[i];
      else break;
    }
    window.open(WEBAPP_URL + jumpToPathname + '?ref=mpm#' + lastVisible.parentElement.id, '_blank').focus();
  }, [jumpToPathname]);

  return (
    <ContinueOnMntContext.Provider value={{ setPathname: setPathname, setButtonLabel: setButtonLabel }}>
      {children}
      <div className={'mnt-ContinueOnMnt'}>
        <div className={'mnt-loadMoreOverlay'}>
          <button className={`mnt-viewOnMnt mnt-viewOnMnt-${APP_LANG}`} onClick={jumpToMnt}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </ContinueOnMntContext.Provider>
  );
};

export { ContinueOnMntContext };
export default withMessages(ContinueOnMntContextProvider);
