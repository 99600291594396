import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import postMapper from './mappers/postMapper';
import tagMapper from './mappers/tagMapper';
import { difference } from '../utils/merging';

const postIdFn = (post) => post.id;

/// Actions --------------------------------------------------------------------

export const tickSearch = createAsyncThunk('search/fetchSearch', async ({ routeMatch, routeParams }, { rejectWithValue }) => {
  try {
    return await routeMatch.tick(routeMatch.prepareParams(routeParams));
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

/**
 * FetchPosts action can be more customized than tickPosts
 * it will be used mainly for lazyload
 */
export const fetchSearch = createAsyncThunk('posts/fetchPosts-0', async ({ routeMatch, routeParams, beforeId, full }, { rejectWithValue }) => {
  const params = routeMatch.prepareParams(routeParams);
  const allResults = full ? '1' : '0';
  try {
    return await routeMatch.lazyLoad(params, beforeId, allResults);
  } catch (ex) {
    return rejectWithValue({ error: ex });
  }
});

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    query: '',
    isLoading: false,
    error: null,
    posts: [],
    authors: [],
    tags: [],
    lives: [],
    hasMorePosts: true,
  },
  reducers: {
    onLocationChanged: (state) => {
      state.query = '';
      state.posts = [];
      state.authors = [];
      state.tags = [];
      state.lives = [];
    },

    nextPagePostResults: (state, { payload }) => {
      state.posts = [...difference(state.posts, payload.posts, postIdFn), ...payload.posts.map(postMapper)];
    },
  },
  extraReducers: {
    [tickSearch.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [tickSearch.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [tickSearch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.posts = action.payload.posts.map(postMapper);
      state.tags = action.payload.tags.map(tagMapper);
      state.lives = action.payload.lives;
      state.hasMorePosts = action.payload.posts.length === action.payload.meta.count;
    },
    [fetchSearch.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchSearch.rejected]: (state, { payload }) => {
      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = payload;
    },
    [fetchSearch.fulfilled]: (state, { payload }) => {
      searchSlice.caseReducers.nextPagePostResults(state, { payload: payload });

      state.timestamp = Math.floor(Date.now() / 1000);
      state.isLoading = false;
      state.error = null;
    },
  },
});

const searchStateSelector = (state) => state.search;

///
/// Selectors ------------------------------------------------------------------
///

/**
 * @returns Boolean
 */
export const isLoadingSelector = createSelector(searchStateSelector, (state) => state.isLoading);

export const tagsSelector = createSelector(searchStateSelector, (state) => state.tags);

export const postsSelector = createSelector(searchStateSelector, (state) => state.posts);

export const hasErrorSelector = createSelector(searchStateSelector, (state) => state.error !== null);

export const hasMorePostsSelector = createSelector(searchStateSelector, (state) => state.hasMorePosts);

export const { onLocationChanged } = searchSlice.actions;

export default searchSlice.reducer;
