import React, { useEffect } from 'react';
import Feed from './Sections/Feed';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import { useHistory, useLocation } from 'react-router-dom';
import FeedArticleSkeleton from '../Components/Skeletons/FeedArticleSkeleton';
import useRedirectOldRoutes from '../Components/Hooks/useRedirectOldRoutes';

const FeedRedirectWrapper = ({ messages }) => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, redirectTo, notFound] = useRedirectOldRoutes(messages.redirectOldURLByHash);

  const hasRedirect = messages.redirectOldURLByHash.filter((it) => it.fastRecognize(location.hash)).length > 0;

  useEffect(() => {
    if (redirectTo !== '') {
      history.replace(redirectTo);
    }
    if (notFound) {
      history.replace(ENV_APP_HOMEPAGE_ROUTE, { fromNonExistingPage: true });
    }
  }, [redirectTo, notFound]);

  return (
    <>
      {hasRedirect && isLoading && (
        <div className={'mnt-Feed'}>
          <FeedArticleSkeleton repeat={10} />
        </div>
      )}
      {!hasRedirect && <Feed />}
    </>
  );
};

export default withMessages(FeedRedirectWrapper);
